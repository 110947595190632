import { IMediaOctFile } from "../features/studies/studiesSlice";
import { API_MEDIA_BASE_PATH } from "../lib/nebulaClient";

const hideSources = true;

/*
    "gif" -> (("image/gif", false, "GIF Image")),
    "png" -> (("image/png", false, "PNG Image")),
    "jpg" -> (("image/jpeg", false, "JPG Image")),
    "jpeg" -> (("image/jpeg", false, "JPEG Image")),
    "tif" -> (("image/tiff", false, "TIFF image")),
    "tiff" -> (("image/tiff", false, "TIFF image")),
    "mp4" -> (("video/mp4", false, "MP4 Video")),
    "csv" -> (("text/csv", false, "CSV File")),
    "dcm" -> (("application/dicom", true, "DCM Image")),
    "dicom" -> (("application/dicom", true, "DICOM Image")),
    "ex.dcm" -> (("application/vnd.voxeleron-oct-zeiss", true, "OCT Image File")),
    "e2e" -> (("application/vnd.voxeleron-oct-heidelberg", true, "OCT Image File")),
    "vol" -> (("application/vnd.voxeleron-oct-heidelberg", true, "OCT Image File")),
    "pdb" -> (("application/vnd.voxeleron-oct-heidelberg", true, "OCT Image File")),
    "edb" -> (("application/vnd.voxeleron-oct-heidelberg", true, "OCT Image File")),
    "sdb" -> (("application/vnd.voxeleron-oct-heidelberg", true, "OCT Image File")),
    "fda" -> (("application/vnd.voxeleron-oct-topcon", true, "OCT Image File")),
    "fds" -> (("application/vnd.voxeleron-oct-topcon", true, "OCT Image File")),
    "img" -> (("application/vnd.voxeleron-oct-zeiss", true, "OCT Image File")),
    "ixd" -> (("application/vnd.voxeleron-ixd", true, "OCT Analysis File")),
    "docMedia" -> (("data/oct-image", true, "OCT Image File")),
    "json" -> (("application/json", false, "JSON FILE")),
    "lst" -> (("application/vnd.voxeleron-oct-generic", true, "OCT image file")),
    "foct" -> (("application/vnd.voxeleron-oct-optoview", true, "OCT Image File")),
    "txt" -> (("text/plain", false, "TXT File")),
    "text" -> (("text/plain", false, "TEXT File")),
    "doc" -> (("application/msword", false, "Word Document")),
    "docx" -> (("application/msword", false, "Word Document")),
    "ppt" -> (("application/mspowerpoint", false, "PowerPoint")),
    "pptx" -> (("application/mspowerpoint", false, "PowerPoint")),
    "pdf" -> (("application/pdf", false, "PDF File")),
    "xls" -> (("application/msexcel", false, "Excel Spreadsheet")),
    "xlsx" -> (("application/msexcel", false, "Excel Spreadsheet")),
    "xml" -> (("application/xml", false, "XML File")),
    "" -> (("application/vnd.voxeleron-oct-maybe-optoview", true, "OCT Image File")),
    "?" -> (("application/octet-stream", true, "OCT Image File"))
*/

export const regroupImages = (images: IMediaOctFile[]) => {
    const result: IMediaOctFile[] = [];
    // pick up the OCTs first
    images.forEach((i) => {
        if (i.Device !== "browser") result.push(i);
    });
    images.forEach((i) => {
        if (i.Device === "browser") result.push(i);
    });
    return result;
};

export const figureComponent = (mediaItem: IMediaOctFile) => {
    switch (figureFlavor(mediaItem)) {
        case "attachment":
        case "csv":
            return "attachment";
        case "photo":
        case "oct-photo":
            return "img";
        case "mp4":
        case "oct-mp4":
            return "video";
        case "pdf":
            return "iframe";
        default:
            return null;
    }
};

export const figureSource = (mediaItem: IMediaOctFile, token: string | undefined) => {
    switch (figureFlavor(mediaItem)) {
        case "attachment":
            return "/assets/images/Attachment.png";
        case "csv":
            return "/assets/images/csv.png";
        case "mp4":
        case "oct-mp4":
            return `${API_MEDIA_BASE_PATH}/t/${token}/media/stream/mp4/${mediaItem.uuid}`;
        case "photo":
        case "oct-photo":
            return `${API_MEDIA_BASE_PATH}/t/${token}/media/stream/jpeg/${mediaItem.uuid}`;
        case "pdf":
            return `${API_MEDIA_BASE_PATH}/t/${token}/media/stream/pdf/${mediaItem.uuid}`;
        default:
            return null;
    }
};

export const figureFlavor = (mediaItem?: IMediaOctFile) => {
    if (!mediaItem) return;
    let flavor: Flavors;
    switch (mediaItem.mimeType.label) {
        case "application/vnd.ms-excel":
            flavor = "csv";
            break;
        case "application/csv":
        case "text/csv":
            flavor = "csv";
            break;
        case "application/pdf":
            flavor = "pdf";
            break;
        case "text/plain":
            flavor = "text";
            break;
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
        case "image/tiff":
        case "image/tif":
            flavor = "photo";
            break;
        case "data/oct-image":
        case "application/vnd.voxeleron-oct-heidelberg":
        case "application/vnd.voxeleron-oct-zeiss":
        case "application/vnd.voxeleron-oct-topcon":
        case "application/vnd.voxeleron-oct-generic":
        case "application/vnd.voxeleron-oct-optoview":
        case "application/vnd.voxeleron-oct-maybe-optoview":
        case "application/octet-stream":
            const thumbFile = mediaItem.ThumbNailFileName ?? "";
            flavor = thumbFile.indexOf(".mp4") > 0 ? "oct-mp4" : "oct-photo";
            break;
        case "video/mp4":
            flavor = "mp4";
            break;
        default:
            flavor = "attachment";
            const mimeLc = mediaItem.mimeType.label.toLowerCase();
            const hasIt = sourceList.find((x) => mimeLc.indexOf(x) >= 0);
            if (hasIt && hideSources) {
                flavor = "hide";
            }
            break;
    }
    return flavor;
};

export const figureCardTitle = (mediaItem?: IMediaOctFile) => {
    if (!mediaItem) return;
    let cardTitle = "";
    switch (figureFlavor(mediaItem)) {
        case "hide":
            return null; // show nothing
        case "mp4":
            cardTitle = "Video";
            break;
        case "oct-photo":
        case "oct-mp4":
            cardTitle = `OCT - ${mediaItem.NumSlices} Slices`;
            break;
        case "pdf":
            cardTitle = "PDF";
            break;
        case "photo":
            cardTitle = "PHOTO";
            break;
        case "csv":
            cardTitle = "REPORT";
            break;
        default:
            cardTitle = "Attachment: " + simplifyMime(mediaItem.mimeType.prettyLabel);
    }
    return cardTitle;
};

export const simplifyMime = (mime: string): string => {
    let typeMaps: { [mime: string]: string } = {
        "application/json": "JSON",
        "application/msword": "MS Word",
        "application/text": "Plain Text",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "MS Word",
        "application/vnd.ms-excel": "MS Excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "MS Excel",
        "application/vnd.ms-powerpoint": "MS PowerPoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "MS PowerPoint",
    };
    if (typeMaps[mime]) return typeMaps[mime];
    else return mime;
};

export type Flavors =
    | "attachment"
    | "csv"
    | "hide"
    | "mp4"
    | "oct-mp4"
    | "oct-photo"
    | "photo"
    | "pdf"
    | "file"
    | "oct"
    | "text";

const sourceList = ["json", "dcm", "dicom", "e2e", "fda", "oct"];
