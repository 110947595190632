import { serviceSlice } from "../../app/serviceSlice";
import { uploadService } from "./nebula.port";
import { IDerivedFile, IFileExtension, IGroup, IRawFile, ITrial, IUploadConfig } from "./uploadSlice";

const extendedApiSlice = serviceSlice
    .enhanceEndpoints({ addTagTypes: ["SESSION_STATUS", "TRIALS", "PATIENTS", "USER_DATA"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTrials: builder.query<ITrial[], void>({
                query: () => uploadService.getTrials,
                providesTags: ["TRIALS"],
            }),
            getUploadConfig: builder.query<IUploadConfig, void>({
                query: () => uploadService.getUploadConfig,
            }),
            getUploadTrack: builder.query<IDerivedFile[], string>({
                query: (uploadSessionId) => () => uploadService.getUploadTrack(uploadSessionId),
            }),
            getValidFileExtensions: builder.query<IFileExtension[], void>({
                query: () => uploadService.getValidFileExtensions,
            }),
            getSessionStatus: builder.query<any, string[]>({
                query: (sessionIds) => () => uploadService.getSessionStatus(sessionIds),
                providesTags: ["SESSION_STATUS"],
            }),
            commitFiles: builder.mutation<IDerivedFile[], IDerivedFile[]>({
                query: (derivedFiles) => () => uploadService.commitFiles(derivedFiles),
                invalidatesTags: ["SESSION_STATUS"],
            }),
            updateCurrentGroup: builder.mutation<any, any>({
                query: (derivedFiles) => () => uploadService.updateCurrentGroup(derivedFiles),
                invalidatesTags: ["TRIALS", "PATIENTS", "USER_DATA"],
            }),
            uploadFile: builder.mutation<
                IRawFile,
                { file: FormData; signal: AbortSignal; onProgress: (progressEvent: any) => void }
            >({
                query:
                    ({ file, signal, onProgress }) =>
                    () =>
                        uploadService.uploadFile(file, signal, onProgress),
            }),
        }),
    });

const findTrial = (trialId: string, trial: ITrial): ITrial | undefined => {
    const isSelected = trial.current.uuid === trialId;

    if (isSelected) {
        return trial;
    }

    if (trial.children.length > 0) {
        return trial.children.find((trial) => findTrial(trialId, trial));
    }

    return undefined;
};

export const getTrialById = (trialId: string, trials: ITrial[]): IGroup | undefined => {
    const activeTrial = findTrial(trialId, trials[0]);

    return activeTrial ? activeTrial.current : undefined;
};

const findActiveTrial = (trial: ITrial): ITrial | undefined => {
    const isSelected = Boolean(trial.current.selected);

    if (isSelected) {
        return trial;
    }

    if (trial.children.length > 0) {
        return trial.children.find((trial) => findActiveTrial(trial));
    }

    return undefined;
};

export const getActiveTrial = (trials: ITrial[]) => {
    const activeTrial = findActiveTrial(trials[0]);

    return activeTrial ? activeTrial.current : undefined;
};

export const {
    useGetTrialsQuery,
    useGetUploadConfigQuery,
    useGetSessionStatusQuery,
    useCommitFilesMutation,
    useUpdateCurrentGroupMutation,
    useGetUploadTrackQuery,
    useGetValidFileExtensionsQuery,
    useUploadFileMutation,
} = extendedApiSlice;
