import { useCallback, useRef, useState } from "react";
import { OctImage } from "../../models/OctImage";
import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import { Boundary } from "../../models/Boundary";
import { useEffect } from "react";
import { IMediaInfo } from "./viewerSlice";
import { CircularProgress } from "@mui/material";

const Slices = (props: { octFileId: string; visitId: string; mediaInfo: IMediaInfo; token: string }) => {
    const { octFileId, mediaInfo, token, visitId } = props;

    const [canvasContext, setCanvasContext] = useState<null | CanvasRenderingContext2D>(null);
    const [isLoading, setIsLoading] = useState(true);

    const container = document.getElementById(`${visitId}-slices-container`);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const sync = useRef<boolean>(true);
    const _images = useRef<OctImage[]>([]);
    const _image = useRef<OctImage>();
    const _isMouseDown = useRef<boolean>(false);
    const _mouseX = useRef<number>(0);
    const _currentSlice = useRef<number>(0);
    const _width = useRef<number>(200);
    const _octArea = useRef<Boundary>();

    useEffect(() => {
        if (canvasRef.current) {
            setCanvasContext(canvasRef.current.getContext("2d"));
        }
    }, [canvasRef]);

    const _drawAll = useCallback(
        (x: number | null = null, y: number | null = null) => {
            if (_images.current.length > 0 && canvasContext) {
                if (y !== null) {
                    _image.current = _images.current[y];
                    _currentSlice.current = y;
                } else {
                    _image.current = _images.current[Math.round(_images.current.length / 2) - 1];
                }

                _mouseX.current = x !== null ? x : _mouseX.current;

                if (_image.current) {
                    _image.current.xhairsSetRaw(_mouseX.current, null);
                    _image.current.draw(canvasContext);
                    _image.current.drawScale(canvasContext, true, mediaInfo.BscanHeightSizeInMicrons, 4);
                    _image.current.drawScale(canvasContext, false, mediaInfo.BscanWidthSizeInMicrons, 8);
                }
            }
        },
        [canvasContext, mediaInfo]
    );

    const _imageIndexSetFromMouse = useCallback(
        (y: number) => {
            if (!_image.current) return 0;
            const maxIndex = mediaInfo.NumSlices - 1;
            let i = 0;
            let b = _image.current.bounds;
            i = (mediaInfo.NumSlices / b.h) * y;
            i = Math.round(i);
            if (i >= maxIndex) {
                i = maxIndex;
            } else if (i < 0) {
                i = 0;
            }
            return i;
        },
        [mediaInfo]
    );

    const _trackEnface = useCallback(
        (event: any) => {
            if (!_image.current) return 0;
            if (event.detail.from === "SLICES") return;
            const srcPixW = _image.current.srcPixW;
            const imageHeight = _image.current.bounds.h;

            const y = (event.detail.y / 100) * imageHeight;

            const x = (event.detail.x / 100) * srcPixW;

            const sliceIndex = _imageIndexSetFromMouse(y);

            _drawAll(x, sliceIndex);
        },
        [_drawAll, _imageIndexSetFromMouse]
    );

    const _syncEnFace = useCallback(() => {
        if (!_image.current) return;
        const srcPixW = _image.current.srcPixW;
        const x = (_mouseX.current / srcPixW) * 100;
        const eventInit = {
            detail: {
                from: "SLICES",
                x: x,
            },
        };
        const mouseEvent = new CustomEvent(`build-${visitId}`, eventInit);
        const elem = document.getElementsByClassName("sync");
        Array.from(elem).map((el) => el.dispatchEvent(mouseEvent));
    }, []);

    const _onMouseUp = (event: MouseEvent) => {
        _isMouseDown.current = false;
    };

    const _onMouseDown = (event: MouseEvent) => {
        _isMouseDown.current = true;
    };

    const _onMouseLeave = (event: MouseEvent) => {
        _isMouseDown.current = false;
    };

    const _onMouseMove = useCallback(
        (event: MouseEvent) => {
            if (_isMouseDown.current && canvasRef.current && _image.current) {
                const cRect = canvasRef.current.getBoundingClientRect();
                const x = event.clientX - cRect.left;
                const y = event.clientY - cRect.top;

                if (_image.current.bounds.isInside(x, y)) {
                    _drawAll(_image.current.xhairsRawX(x), null);
                    _syncEnFace();
                }
            }
        },
        [_drawAll, _syncEnFace]
    );

    const _onWindowResize = (e: any) => {
        const containerSize = document.getElementById(`${visitId}-slices-container`)!.getBoundingClientRect();
        canvasRef!.current!.width = containerSize.width;
        canvasRef!.current!.height = containerSize.height;

        _image!.current!.rawFit(containerSize.width - 90, containerSize.height - 30);

        _drawAll(null, null);
    };

    useEffect(() => {
        const containerSize = document.getElementById(`${visitId}-slices-container`)!.getBoundingClientRect();
        if (container && container.clientWidth > 200) _width.current = containerSize.width;
        const height = 200;
        const paddingTop = 5;
        const scaleWidth = 100;
        const scaleHeight = 20 + paddingTop;
        if (canvasRef.current && canvasContext) {
            canvasRef.current.width = containerSize.width;
            canvasRef.current.height = containerSize.height;
            canvasRef.current.addEventListener("mousemove", _onMouseMove, false);
            canvasRef.current.addEventListener("mouseup", _onMouseUp, false);
            canvasRef.current.addEventListener("mousedown", _onMouseDown, false);
            canvasRef.current.addEventListener("mouseleave", _onMouseLeave, false);
            canvasRef.current.addEventListener(`build-${visitId}`, _trackEnface, false);
            window.addEventListener(`resize`, _onWindowResize, false);
        }
        const _bscanAreaWidth = _width.current - scaleWidth;
        const _bscanAreaHeight = height - scaleHeight;

        _octArea.current = new Boundary(scaleWidth, 5, _bscanAreaWidth, _bscanAreaHeight);

        return () => {
            window.removeEventListener(`resize`, _onWindowResize, false);
        };
    }, [canvasContext, _onMouseMove, _trackEnface, container]);

    useEffect(() => {
        let imgs: OctImage[] = [];
        let imageLoadCounter = 0;
        for (let i = 0; i < mediaInfo.NumSlices; i++) {
            const imageElement = new Image();
            imageElement.id = `i${i}`;
            imageElement.src = `${API_MEDIA_BASE_PATH}/t/${token}/media/image/${octFileId}/slice.png/${i}`;
            imageElement.onload = (e: Event) => {
                const containerSize = document.getElementById(`${visitId}-slices-container`)!.getBoundingClientRect();
                if (!_octArea.current) return;
                const img = e.currentTarget as HTMLImageElement;
                const info = mediaInfo;
                const w = info.BscanWidthDim;
                const h = info.BscanHeightDim;
                const actualW = info.BscanWidthSizeInMicrons;
                const actualH = info.BscanHeightSizeInMicrons;
                const unit = "μm";
                const oct = new OctImage(img, _octArea.current, 1, w, h, actualW, actualH, unit, unit, false);
                oct.id = `image ${i}`;
                oct.rawFit(containerSize.width - 90, containerSize.height - 30);
                oct.centerHorizontal(_octArea.current, _width.current);
                imgs[i] = oct;
                imageLoadCounter++;
                if (imageLoadCounter === mediaInfo.NumSlices) {
                    setIsLoading(false);
                    _images.current = imgs;
                    _drawAll(null, null);
                }
            };
        }
    }, [_drawAll, mediaInfo, token, octFileId]);

    useEffect(() => {
        _drawAll(_mouseX.current, null);
    });

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        position: "relative",
                        top: 50,
                    }}
                >
                    <CircularProgress />
                </div>
            )}
            <canvas
                id={`${visitId}-slice`}
                key={`${visitId}-slice`}
                className={sync.current ? "sync" : ""}
                ref={canvasRef}
            />
        </>
    );
};

export default Slices;
