import { serviceSlice } from "../../app/serviceSlice";
import { CRFService } from "./nebula.port";
import { Crf, CrfVisit, ICRFEEditRequest, Laterality } from "./nebula.service";

export const mergeCollections = (...arrays: Record<any, any>[]) => {
    const merged = {};

    // @ts-ignore
    arrays.forEach((data) => data.forEach((o) => Object.assign((merged[o.fieldName] ??= {}), o)));

    return Object.values(merged);
};

const extendedApiSlice = serviceSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPatientCrf: builder.query<Crf, string>({
            query: (patientId) => () => CRFService.getPatientCrf(patientId),
        }),
        removeCrfFields: builder.mutation<
            void,
            { patientId: string; visitId: string; laterality: Laterality; fieldsToRemove: string[] }
        >({
            query:
                ({ patientId, visitId, laterality, fieldsToRemove }) =>
                () =>
                    CRFService.removeCrfValues(patientId, visitId, laterality, fieldsToRemove),
            async onQueryStarted({ patientId, fieldsToRemove, visitId, laterality }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatientCrf", patientId, (draft) => {
                        draft.visits.forEach((visit: CrfVisit) => {
                            if (visit.uuid === visitId) {
                                visit.data.forEach((crfReading) => {
                                    if (crfReading.laterality === laterality) {
                                        crfReading.values = crfReading.values.filter(({ fieldName }) => {
                                            return !fieldsToRemove.includes(fieldName);
                                        });
                                    }
                                });
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        editCRF: builder.mutation<void, { patientId: string; crfUpdate: ICRFEEditRequest }>({
            query:
                ({ patientId, crfUpdate }) =>
                () =>
                    CRFService.editCRF(patientId, crfUpdate),
            async onQueryStarted({ patientId, crfUpdate }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatientCrf", patientId, (draft) => {
                        draft.visits.forEach((visit: CrfVisit) => {
                            if (visit.uuid === crfUpdate.visitId) {
                                visit.data.forEach((crfReading) => {
                                    if (crfReading.laterality === crfUpdate.laterality) {
                                        // update values
                                        if (crfUpdate.data) {
                                            const updatedValues = mergeCollections(crfReading.values, crfUpdate.data);
                                            // @ts-ignore
                                            crfReading.values = updatedValues;
                                        }
                                        // update notes
                                        if (crfUpdate.notes !== undefined) {
                                            crfReading.notes = crfUpdate.notes;
                                        }
                                    }
                                });
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const { useEditCRFMutation, useGetPatientCrfQuery, useRemoveCrfFieldsMutation } = extendedApiSlice;
