import { serviceSlice } from "../../app/serviceSlice";
import { ICRFData, IStudy } from "./studiesSlice";
import { studyService } from "./nebula.port";

export const extendedApiSlice = serviceSlice.enhanceEndpoints({ addTagTypes: ["VISITS"] }).injectEndpoints({
    endpoints: (builder) => ({
        getPatientVisits: builder.query<{ studies: IStudy[]; crfs: ICRFData }, string>({
            query: (patientId) => () => studyService.getPatientStudies(patientId),
            transformResponse: (responseData: { studies: IStudy[]; crfs: ICRFData }) => {
                const orderedByDateStudies = responseData.studies.sort((a, b) =>
                    a.imagingProcedure.orderDate < b.imagingProcedure.orderDate ? 1 : -1
                );

                return {
                    studies: orderedByDateStudies,
                    crfs: responseData.crfs,
                };
            },
            providesTags: ["VISITS"],
        }),
        getMediaToken: builder.mutation<string, string | void>({
            query: (expiration) => () => {
                if (expiration) return studyService.getMediaToken(expiration);

                return studyService.getMediaToken();
            },
            transformResponse(rawResult: { token: string }) {
                return rawResult.token;
            },
        }),
        deleteStudy: builder.mutation<void, string>({
            query: (studyId) => () => studyService.deleteStudy(studyId),
            invalidatesTags: ["VISITS"],
        }),
        attachFile: builder.mutation<void, FormData>({
            query: (newFile) => () => studyService.attachFile(newFile),
            invalidatesTags: ["VISITS"],
        }),
        downloadStudy: builder.mutation<Blob, string>({
            query: (studyUrl) => () => studyService.downloadStudy(studyUrl),
        }),
        editNote: builder.mutation<unknown, { note: string; mediaUuid: string; patientId: string }>({
            query:
                ({ note, mediaUuid }) =>
                () =>
                    studyService.editNote(mediaUuid, note),
            // Update cached visits without hitting the server (optimistic updates)
            async onQueryStarted({ note, mediaUuid, patientId }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatientVisits", patientId, (draft) => {
                        draft.studies.forEach((study, i) => {
                            const mediaIndex = study.mediaOctFile.findIndex(
                                (media: { uuid: string }) => media.uuid === mediaUuid
                            );

                            if (mediaIndex !== -1) {
                                draft.studies[i].mediaOctFile[mediaIndex].Comment = note;
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetPatientVisitsQuery,
    useGetMediaTokenMutation,
    useDeleteStudyMutation,
    useAttachFileMutation,
    useDownloadStudyMutation,
    useEditNoteMutation,
} = extendedApiSlice;
