import { Box } from "@mui/material";
import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import SizedContainerPDF from "../../components/PDF/SizedConteiner";
import React, { memo } from "react";

interface IiframeMediaViewerProps {
    uuid: string;
    token: string;
}
const PdfMediaViewer: React.FC<IiframeMediaViewerProps> = ({ uuid, token }) => {
    return (
        <Box>
            <SizedContainerPDF pdfUrl={`${API_MEDIA_BASE_PATH}/t/${token}/media/stream/pdf/${uuid}`} />
        </Box>
    );
};

export default memo(PdfMediaViewer);
