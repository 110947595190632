import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import Login from "./features/auth/login/Login";
import Layout from "./features/layout/Layout";
import VisitsAnalyzer from "./features/media/VisitsAnalyzer";
import Upload from "./features/upload/Upload";
import { ConfirmationModalProvider } from "./components/ConfirmationDialog";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProtectedRoute from "./features/auth/login/components/ProtectedRoute";
import NoMatch from "./components/NoMatch/NoMatch";
import { Box } from "@mui/system";
import Dashboard from "./features/Dashboard/Dashboard";
import Recover from "./features/auth/recover/Recover";
import RecoverConfirm from "./features/auth/recover/RecoverConfirm";
import { useEffect } from "react";
import { useAppSelector } from "./app/hooks";
import { isUserLoggedIn } from "./features/auth/login/loginSlice";
import useTimerHook from "./hooks/useTimerHook/useTimerHook";

function App() {
    const loggedIn = useAppSelector(isUserLoggedIn);
    const { timer } = useTimerHook();

    // Auto-logout
    useEffect(() => {
        if (loggedIn) {
            // if the user is logged in, start timer
            timer.startInterval();
        } else {
            // if the user is logged out, clean it
            timer.cleanUp();
        }
    }, [loggedIn]);

    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            autoHideDuration={3000}
        >
            <ConfirmationModalProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Router>
                        <Box sx={{ height: "100vh" }} className="App">
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <Route exact path="/password/recover" component={Recover} />
                                <Route exact path="/password/recover/confirm/:token" component={RecoverConfirm} />
                                <Route path="/login" component={Login} />
                                <ProtectedRoute path="/patients">
                                    <Layout />
                                </ProtectedRoute>
                                <ProtectedRoute path="/visits/:patientId">
                                    <VisitsAnalyzer />
                                </ProtectedRoute>
                                <ProtectedRoute path="/upload" permissions={["Upload"]}>
                                    <Upload />
                                </ProtectedRoute>
                                <ProtectedRoute path="/dashboard">
                                    <Dashboard />
                                </ProtectedRoute>
                                <Route path="*">
                                    <NoMatch />
                                </Route>
                            </Switch>
                        </Box>
                    </Router>
                </LocalizationProvider>
            </ConfirmationModalProvider>
        </SnackbarProvider>
    );
}

export default App;
