import axios, { AxiosError } from "axios";
import config from "../config.json";
import { DataError } from "../shared/DataError";
import { DataResponse } from "../shared/DataResponse";

export const API_V1_BASE_PATH = process.env.REACT_APP_NEBULA_SERVER_V1_URL || config.api_baseurl.v1;
export const API_V2_BASE_PATH = process.env.REACT_APP_NEBULA_SERVER_V2_URL || config.api_baseurl.v2;
export const API_UPLOAD_BASE_PATH = process.env.REACT_APP_NEBULA_SERVER_UPLOAD_URL || config.api_baseurl.upload;
export const API_MEDIA_BASE_PATH = process.env.REACT_APP_NEBULA_SERVER_MEDIA_URL || config.api_baseurl.media;
export const API_ORION_BASE_PATH = process.env.REACT_APP_NEBULA_SERVER_ORION_URL || config.api_baseurl.orion;

const USER_CREDENTIALS_KEY = "userCredentials";

export const getUserToken = () => {
    const userCredentials = window.localStorage.getItem(USER_CREDENTIALS_KEY);
    return userCredentials ? JSON.parse(userCredentials).token : null;
};

const DEFAULT_HEADERS = {
    "Content-Type": "application/json",
};

export const getHeadersWithToken = () => {
    const token = getUserToken();

    if (!token) throw new Error("No access token found! Please make sure the user is logged in.");

    return {
        ...DEFAULT_HEADERS,
        "X-Auth-Token": `${token}`,
        "Csrf-Token": "nocheck",
    };
};

export type ResponseType = "arraybuffer" | "blob" | "document" | "json" | "text" | "stream";

interface NebulaClientConfig {
    url?: string;
    data?: any;
    withAuth?: boolean;
    headers?: Record<string, string>;
    responseType?: ResponseType;
    signal?: AbortSignal;
    onUploadProgress?: (progressEvent: any) => void;
    withCredentials?: boolean;
}

interface NebulaClient {
    get<T>(url: string, config?: NebulaClientConfig): Promise<DataResponse<T>>;
    post<T>(url: string, data: any, config?: NebulaClientConfig): Promise<DataResponse<T>>;
    put<T>(url: string, data: any, config?: NebulaClientConfig): Promise<DataResponse<T>>;
    delete<T>(url: string, config?: NebulaClientConfig): Promise<DataResponse<T>>;
}

declare module "axios" {
    export interface AxiosRequestConfig {
        withAuth?: boolean;
    }
}

const handleError = (err: AxiosError) => {
    let error: DataError;

    if (err.response) {
        if (err.response.request.status === 401 || err.request.responseURL.includes("signIn")) {
            error = {
                kind: "Unauthorized",
            };
        } else if (err.response.request.status === 404) {
            error = {
                kind: "NotFound",
            };
        } else {
            error = {
                kind: "ApiError",
                statusCode: err.response.status,
                message: err.response.data,
            };
        }
    } else if (err.request) {
        error = {
            kind: "UnexpectedError",
            message: err.request,
        };
    } else {
        error = {
            kind: "UnexpectedError",
            message: err,
        };
    }

    return { error };
};

export const getNebulaClient = (baseUrl: string): NebulaClient => {
    const client = axios.create({
        baseURL: baseUrl,
        withAuth: true,
    });

    client.interceptors.request.use(
        (config) => {
            if (config.withAuth) {
                config.headers = getHeadersWithToken();
            } else {
                config.headers = DEFAULT_HEADERS;
            }

            return config;
        },
        (error) => Promise.reject(error)
    );

    client.interceptors.response.use(function (response) {
        if (response.request.responseURL.includes("signIn")) {
            return {
                error: { kind: "Unauthorized" },
            };
        }

        return { data: response.data, meta: { status: response.status, headers: response.headers } };
    }, handleError);

    return client;
};

export const nebulaClient = getNebulaClient(API_V1_BASE_PATH);
export const nebulaClient2 = getNebulaClient(API_V2_BASE_PATH);
export const nebulaUploadClient = getNebulaClient(API_UPLOAD_BASE_PATH);
export const nebulaOrionClient = getNebulaClient(API_ORION_BASE_PATH);
