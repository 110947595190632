import React from "react";
import { Chip } from "@mui/material";
import { Done as DoneIcon, Remove as RemoveIcon } from "@mui/icons-material";

interface IFilterPillProps {
    onActivate: () => void;
    onDeactivate: () => void;
    label: string;
    active: boolean;
}

const FilterPill: React.FC<IFilterPillProps> = ({ label, onActivate, onDeactivate, active }) => {
    const handleActivate = () => {
        onActivate();
    };

    const handleDesactivate = () => {
        onDeactivate();
    };

    if (active) {
        return (
            <Chip label={label} color="primary" variant="outlined" icon={<DoneIcon />} onClick={handleDesactivate} />
        );
    }

    return <Chip label={label} variant="outlined" icon={<RemoveIcon />} onClick={handleActivate} />;
};

export default FilterPill;
