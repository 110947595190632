import React from "react";
import { Box } from "@mui/system";
import { ArrowForwardIosSharp, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    CircularProgress,
    Typography,
} from "@mui/material";
import CRFVisitTable from "../crf/CRFTable";
import { styled } from "@mui/styles";
import { useAppSelector } from "../../app/hooks";
import { IPatient, selectCurrentPatient } from "../patients/patientsSlice";
import { CrfField, CrfReading, CrfVisit } from "../crf/nebula.service";

const CustomAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={1} square {...props} />)(
    () => ({
        backgroundColor: "#1f2735",
    })
);

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />} {...props} />
))(() => ({
    "flexDirection": "row-reverse",
    "& .MuiAccordionSummary-content": {
        margin: "0px",
    },
}));

interface ICRFVisitAccordionProps {
    title: string;
    fields: CrfField[];
    CRFData: CrfReading[];
    visit: {
        name: string;
        uuid: string;
    };
}

const CRFVisitAccordion: React.FC<ICRFVisitAccordionProps> = ({ title, fields, CRFData, visit }) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const currentPatient = useAppSelector(selectCurrentPatient);

    const patient = currentPatient as IPatient;

    const handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpanded(expanded);
    };

    return (
        <Box sx={{ mt: 16 }}>
            <Box>
                <CustomAccordion
                    TransitionProps={{ unmountOnExit: false }}
                    expanded={expanded}
                    onChange={handleAccordionChange}
                    sx={{ margin: "0px", backgroundColor: "#1f2735" }}
                >
                    <CustomAccordionSummary sx={{ backgroundColor: "#1f2735" }} expandIcon={<ExpandMore />}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                            <Typography sx={{ padding: "10px" }}>{title}</Typography>
                        </Box>
                    </CustomAccordionSummary>
                    <AccordionDetails sx={{ p: 16, backgroundColor: "#1f2735" }}>
                        <CRFVisitTable patient={patient} fields={fields} data={CRFData} visit={visit} />
                    </AccordionDetails>
                </CustomAccordion>
            </Box>
        </Box>
    );
};

interface ICRFTabProps {
    visits?: CrfVisit[];
}
const CRFTab: React.FC<ICRFTabProps> = ({ visits }) => {
    if (!visits) {
        return (
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            {visits!.map((visit) => (
                <CRFVisitAccordion
                    fields={visit.fields}
                    key={visit.uuid}
                    CRFData={visit.data}
                    visit={{ name: visit.visitName, uuid: visit.uuid }}
                    title={`CRF for: ${visit.visitName}`}
                />
            ))}
        </>
    );
};

export default CRFTab;
