import FilterPill from "./FilterPill";
import { Box } from "@mui/system";
import React, { useCallback, useMemo } from "react";
import { IStudy } from "./studiesSlice";
import useSelectStudiesByType from "./useSelectStudiesByType";

interface StudiesFiltersProps {
    visits: IStudy[];
    onSelect: (studiesIds: Set<string>) => void;
    selectedStudies: Set<string>;
}
const StudiesFilters: React.FC<StudiesFiltersProps> = ({ visits, selectedStudies, onSelect }) => {
    const uniqueStudyTypes = useMemo(() => {
        const studyTypes: Record<string, string> = {};

        visits.forEach((visit) => {
            visit.mediaOctFile.forEach((media) => {
                studyTypes[media.mimeType.label] = media.mimeType.prettyLabel;
            });
        });

        return studyTypes;
    }, [visits]);

    const handleSelectAll = useCallback(() => {
        if (visits) {
            const allStudiesIds = visits.map((study) => study.mediaOctFile.map((octFile) => octFile.uuid)).flat();

            const newSelectedStudies = new Set(allStudiesIds);
            onSelect(newSelectedStudies);
        }
    }, [visits]);

    const handleDeselectAll = useCallback(() => {
        onSelect(new Set());
    }, []);
    const areAllSelected = useMemo(() => {
        if (visits.length === 0) return false;
        const studiesAmount = visits!.map((visit) => visit.mediaOctFile.map((study) => study)).flat().length;

        return studiesAmount === selectedStudies.size;
    }, [selectedStudies, visits]);

    const renderFilters = useCallback(() => {
        return Object.entries(uniqueStudyTypes).map(([studyType, studyLabel]) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { areAllStudyTypesSelected, selectAllStudiesType, deselectAllStudiesType } = useSelectStudiesByType(
                studyType,
                selectedStudies,
                onSelect
            );

            return (
                <FilterPill
                    key={studyType}
                    label={studyLabel}
                    onActivate={() => selectAllStudiesType(visits)}
                    onDeactivate={() => deselectAllStudiesType(visits)}
                    active={areAllStudyTypesSelected(visits, selectedStudies)}
                />
            );
        });
    }, [selectedStudies, visits]);

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
            <FilterPill
                label="All Studies"
                onActivate={handleSelectAll}
                onDeactivate={handleDeselectAll}
                active={areAllSelected}
            />
            {renderFilters()}
        </Box>
    );
};

export default StudiesFilters;
