import { Select, MenuItem, IconButton, SelectChangeEvent, Chip } from "@mui/material";
import {
    OpenInFull as OpenInFullIcon,
    CloseFullscreen as CloseFullscreenIcon,
    ContentCopy as ContentCopyIcon,
    Download as DownloadIcon,
    DeleteOutline as DeleteOutlineIcon,
    TableView as TableViewIcon,
    StickyNote2 as NoteIcon,
} from "@mui/icons-material";
import { Formatter } from "../../utils/Formtter";
import { Box } from "@mui/system";
import { selectStudyById, selectVisitFromStudy } from "./viewerSlice";
import { IStudy, IMediaOctFile } from "../studies/studiesSlice";
import {
    useGetPatientVisitsQuery,
    useDeleteStudyMutation,
    useDownloadStudyMutation,
    useEditNoteMutation,
} from "../studies/dataAccess";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import MenuList from "../../components/MenuList/MenuList";
import LateralityPill from "../studies/StudyCard/LateralityPill";
import CopyCaseInfo from "./CopyCaseInfo";
import { useGetPatientByIdQuery } from "../patients/dataAccess";
import usePermissions from "../permissions/usePermissions";
import { useSnackbar } from "notistack";
import ToolTip from "../../components/Tooltip/Tooltip";
import DisplayText from "../../components/DisplayText/DisplayText";
import ReportEligibility from "./ReportEligibility";
import NoteDialog from "../studies/StudyCard/NoteDialog";

interface IMediaViewerHeaderProps {
    studyId: string;
    mediaViewerId: string;
    onVisitChange: (visitId: string) => void;
    isFullsScreen: boolean;
    onFullScreenChange: () => void;
    handleCloseMediaViewer: () => void;
    onOpenCRF: (selectedVisi: IStudy) => void;
}

const MediaViewerHeader: React.FC<IMediaViewerHeaderProps> = ({
    studyId,
    onVisitChange,
    mediaViewerId,
    isFullsScreen,
    onFullScreenChange,
    handleCloseMediaViewer,
    onOpenCRF,
}) => {
    const [noteDialogOpen, setNoteDialogOpen] = useState(false);
    const [copyQueryInfoOpen, setCopyQueryInfoOpen] = useState<boolean>(false);
    const [reportEligibilityOpen, setReportEligibilityOpen] = useState<boolean>(false);
    const { hasPermissions } = usePermissions();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [editNote] = useEditNoteMutation();

    const { patientId } = useParams<{ patientId: string }>();

    const { data: patientData } = useGetPatientByIdQuery(patientId);

    const [deleteStudy] = useDeleteStudyMutation();
    const [downloadStudy] = useDownloadStudyMutation();

    const { data } = useGetPatientVisitsQuery(patientId);

    const studyVisit = selectVisitFromStudy(data!.studies, studyId as string) as IStudy;

    const study = selectStudyById(data!.studies, studyId as string) as IMediaOctFile;

    const selectHandler = (event: SelectChangeEvent) => {
        const selectedVisitId = event.target.value;
        onVisitChange(selectedVisitId);
    };

    const handleOpenCopyCaseInfo = () => {
        setCopyQueryInfoOpen(false);
        // resetMailCopied();
    };

    const handleCloseCopyCaseInfo = () => {
        setCopyQueryInfoOpen(true);
    };

    const handleOpenReportEligibility = () => {
        setReportEligibilityOpen(true);
    };

    const handleCloseReportEligibility = () => {
        setReportEligibilityOpen(false);
    };

    useEffect(() => {
        document.addEventListener("fullscreenchange", onFullScreenChange, false);
        document.addEventListener("mozfullscreenchange", onFullScreenChange, false);
        document.addEventListener("MSFullscreenChange", onFullScreenChange, false);
        document.addEventListener("webkitfullscreenchange", onFullScreenChange, false);

        return () => {
            document.removeEventListener("fullscreenchange", onFullScreenChange, false);
            document.removeEventListener("mozfullscreenchange", onFullScreenChange, false);
            document.removeEventListener("MSFullscreenChange", onFullScreenChange, false);
            document.removeEventListener("webkitfullscreenchange", onFullScreenChange, false);
        };
    }, []);

    const getStudyUrL = (study: IMediaOctFile): string => {
        const fileExtension = study.fullUploadPath.split(".").pop();

        const kind = study.mimeType.label === "data/oct-image" ? "oct-image" : fileExtension;

        return `/media/stream/${kind}/${study.uuid}`;
    };

    const handleDownloadStudy = async () => {
        enqueueSnackbar("Download is being prepared...", {
            variant: "info",
            persist: true,
        });

        try {
            const studyUrl = getStudyUrL(study);

            const fileBlob = await downloadStudy(studyUrl).unwrap();

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([fileBlob]));

            // Crete link element
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", study.FileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link!.parentNode!.removeChild(link);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("There was a problem with the download.", {
                variant: "error",
            });
        }
    };

    const handleDeleteStudy = async () => {
        await deleteStudy(study.uuid);
        handleCloseMediaViewer();
    };

    const menuListOptions = [
        ...(hasPermissions(["QC"])
            ? [{ label: "Query: Copy Case Info", Icon: <ContentCopyIcon />, onClick: handleCloseCopyCaseInfo }]
            : []),
        ...(hasPermissions(["QC"])
            ? [{ label: "Report Eligibility", Icon: <ContentCopyIcon />, onClick: handleOpenReportEligibility }]
            : []),
        {
            label: "Open CRF table",
            Icon: <TableViewIcon />,
            onClick: () => onOpenCRF(studyVisit),
        },
        ...(hasPermissions(["Download"])
            ? [
                  {
                      label: "Download",
                      Icon: <DownloadIcon />,
                      onClick: handleDownloadStudy,
                  },
              ]
            : []),
        ...(hasPermissions(["Delete"])
            ? [
                  {
                      label: "Remove",
                      Icon: <DeleteOutlineIcon color="error" />,
                      labelStyle: { color: "#f44336" },
                      onClick: handleDeleteStudy,
                  },
              ]
            : []),
    ];

    const handleOpenNoteDialog = () => setNoteDialogOpen(true);

    const handleEditNote = async (newNote: string) => {
        try {
            await editNote({ mediaUuid: study.uuid, note: newNote, patientId: patientId }).unwrap();
            enqueueSnackbar("Note successfully edited.", { variant: "success" });
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
            throw e;
        }
    };

    const handleCloseNoteDialog = () => setNoteDialogOpen(false);

    const NotificationComponent = study.Comment ? (
        <ToolTip content={<DisplayText type="bodyXSmall" text={study.Comment} />}>
            <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                <Chip
                    icon={<NoteIcon />}
                    onClick={handleOpenNoteDialog}
                    label="Notes"
                    size="small"
                    color={study.Comment ? "warning" : "default"}
                />
            </Box>
        </ToolTip>
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <Chip
                icon={<NoteIcon />}
                onClick={handleOpenNoteDialog}
                label="Add notes"
                size="small"
                color={study.Comment ? "warning" : "default"}
            />
        </Box>
    );

    return (
        <>
            <CopyCaseInfo
                isOpen={copyQueryInfoOpen}
                study={study}
                visitName={studyVisit.imagingProcedure.visit || "Visit"}
                siteName={studyVisit.imagingProcedure.site}
                patientId={patientData!.patientId}
                onClose={handleOpenCopyCaseInfo}
                visitUuid={studyVisit.imagingProcedure.visit || "Visit"}
            />
            <ReportEligibility
                siteName={studyVisit.imagingProcedure.site}
                visitName={studyVisit.imagingProcedure.visit || "Visit"}
                patientId={patientData!.patientId}
                isOpen={reportEligibilityOpen}
                study={study}
                visitUuid={studyVisit.imagingProcedure.visit || "Visit"}
                onClose={handleCloseReportEligibility}
            />
            <Box
                sx={{
                    display: "flex",
                    bgcolor: "#0e1118",
                    flex: 1,
                    px: 20,
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Select
                    id="visit-dropdown"
                    value={studyVisit.imagingProcedure.uuid}
                    onChange={selectHandler}
                    variant="standard"
                >
                    {data!.studies!.map((study) => (
                        <MenuItem key={study.imagingProcedure.uuid} value={study.imagingProcedure.uuid}>
                            {Formatter.date(study.imagingProcedure.orderDate)}
                        </MenuItem>
                    ))}
                </Select>
                {study.Laterality ? <LateralityPill laterality={study.Laterality} /> : null}
                <Box sx={{ display: "flex", alignContent: "center", gap: 5, alignItems: "center" }}>
                    {hasPermissions(["QC"]) ? NotificationComponent : null}
                </Box>
                <Box sx={{ display: "flex" }}>
                    {isFullsScreen ? (
                        <IconButton
                            onClick={() => {
                                document.exitFullscreen();
                            }}
                            size="small"
                        >
                            <CloseFullscreenIcon />
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => {
                                document.getElementById(`${mediaViewerId}-container`)!.requestFullscreen();
                            }}
                            size="small"
                        >
                            <OpenInFullIcon />
                        </IconButton>
                    )}
                    {menuListOptions.length > 0 ? (
                        <Box sx={{ mr: -9 }}>
                            <MenuList options={menuListOptions} />
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <NoteDialog
                initialNotes={study.Comment}
                open={noteDialogOpen}
                onClose={handleCloseNoteDialog}
                onAccept={handleEditNote}
            />
        </>
    );
};

export default MediaViewerHeader;
