import { Timer } from "../../utils/Timer";
import { logoutUser } from "../../features/auth/login/loginSlice";
import { useMemo } from "react";
import { useAppDispatch } from "../../app/hooks";

const DEFAULT_TIMEOUT = 15;

const useTimerHook = () => {
    const dispatch = useAppDispatch();

    const timer = useMemo(
        () =>
            new Timer({
                timeout: DEFAULT_TIMEOUT, // in minutes
                onTimeout: () => {
                    dispatch(logoutUser());
                },
            }),
        []
    );

    return { timer };
};

export default useTimerHook;
