import React, { memo, useState } from "react";
import { Paper, IconButton } from "@mui/material";
import MediaViewerFile from "./MediaViewerFile";
import MediaViewerHeader from "./MediaViewerHeader";
import { Close } from "@mui/icons-material";
import Carousel from "../carousel/Carousel";
import theme from "../../app/theme";
import { selectVisitFromStudy } from "./viewerSlice";
import { IStudy } from "../studies/studiesSlice";
import { useGetPatientVisitsQuery } from "../studies/dataAccess";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useGetStudyIdFromUri from "../../hooks/useGetStudyIdFromUri/useGetStudyIdFromUri";
import queryString from "query-string";
import { getQueryParamFromStudyId } from "./utils";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import Tooltip from "../../components/Tooltip/Tooltip";

interface IMediaViewerProps {
    mediaViewerId: string;
    onOpenCRF: (selectedVisi: IStudy) => void;
}

const MediaViewer: React.FC<IMediaViewerProps> = ({ mediaViewerId, onOpenCRF }) => {
    const history = useHistory();
    const location = useLocation();

    const { patientId } = useParams<{ patientId: string }>();

    const { getStudyIdByMediaViewerId, getMediaViewerIdIndex, selectedMediaViewerIds } = useGetStudyIdFromUri();

    const studyId = getStudyIdByMediaViewerId(mediaViewerId);
    const mediaCardIndex = getMediaViewerIdIndex(mediaViewerId);

    const { data } = useGetPatientVisitsQuery(patientId);

    const studyVisit = selectVisitFromStudy(data!.studies as IStudy[], studyId as string) as IStudy;

    const handleCarouselItemClick = (studyId: string) => {
        const updatedStudiesIds = [...selectedMediaViewerIds];

        const currentCardIndexIdentifier = updatedStudiesIds[mediaCardIndex].split(":")[0];

        updatedStudiesIds[mediaCardIndex] = `${currentCardIndexIdentifier}:${studyId}`;

        history.push({
            pathname: location.pathname,
            search: queryString.stringify({ st: updatedStudiesIds }),
        });
    };

    const handleVisitChange = (visitId: string) => {
        const visit = data!.studies!.find((visit) => visit.imagingProcedure.uuid === visitId) as IStudy;

        const firstVisitStudy = visit.mediaOctFile[0];

        const updatedStudiesIds = [...selectedMediaViewerIds];

        updatedStudiesIds[mediaCardIndex] = getQueryParamFromStudyId(firstVisitStudy.uuid);

        history.push({
            pathname: location.pathname,
            search: queryString.stringify({ st: updatedStudiesIds }),
        });
    };

    const handleCloseMediaViewer = () => {
        const updatedStudiesIds = [...selectedMediaViewerIds];

        updatedStudiesIds.splice(mediaCardIndex, 1);

        history.push({
            pathname: location.pathname,
            search: queryString.stringify({ st: updatedStudiesIds }),
        });
    };

    const [isFullsScreen, setIsFullsScreen] = useState(false);

    const onFullScreenChange = () => {
        // @ts-ignore
        const isFullScreen = document.mozFullScreen || document.webkitIsFullScreen;

        setIsFullsScreen(isFullScreen);
    };

    return (
        <Paper
            sx={{
                border: "3px solid",
                borderColor: theme.palette.success.main,
                display: "flex",
                flexDirection: "column",
                aspectRatio: "2.4",
            }}
            id={`${mediaViewerId}-container`}
        >
            <Box sx={{ display: "flex" }}>
                <Box
                    sx={{
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flex: "0 1 140px",
                        maxWidth: 140,
                        backgroundColor: theme.palette.success.main,
                        borderRadius: "0px 0px 6px 0px",
                        zIndex: 2,
                        marginRight: -5,
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <Tooltip
                            content={`${studyVisit.imagingProcedure.visit || "Visit"} (${new Date(
                                studyVisit.imagingProcedure.orderDate
                            ).toLocaleDateString()}) | ${studyVisit.imagingProcedure.trialName}`}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    color: "black",
                                    fontWeight: "bold",
                                }}
                            >
                                <Box sx={{ flex: 1, width: "100%" }}>
                                    <DisplayText
                                        style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                                        type="bodyMedium"
                                        text={studyVisit.imagingProcedure.visit || "Visit"}
                                    />
                                </Box>

                                {!isFullsScreen && (
                                    <IconButton onClick={handleCloseMediaViewer} size="small">
                                        <Close
                                            sx={{
                                                height: theme.spacing(15),
                                                width: theme.spacing(15),
                                                color: "black",
                                            }}
                                        />
                                    </IconButton>
                                )}
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                {!studyVisit ? null : (
                    <MediaViewerHeader
                        onOpenCRF={onOpenCRF}
                        handleCloseMediaViewer={handleCloseMediaViewer}
                        isFullsScreen={isFullsScreen}
                        onFullScreenChange={onFullScreenChange}
                        mediaViewerId={mediaViewerId}
                        onVisitChange={handleVisitChange}
                        studyId={studyId as string}
                    />
                )}
            </Box>

            {!studyVisit ? (
                <Box
                    sx={{
                        height: "507px",
                        margin: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        p: 24,
                    }}
                >
                    <DisplayText type="bodyLarge" variant="semiBold" text="You don't have access to this study" />
                    <DisplayText
                        type="bodySmall"
                        variant="regular"
                        text="Make sure the study exists. In case it is, please ask for authorization to your system administrator."
                        style={{ textAlign: "center" }}
                    />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            width: "100%",
                            p: 10,
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "auto",
                        }}
                    >
                        <MediaViewerFile key={studyId} mediaViewerId={mediaViewerId} studyId={studyId as string} />
                    </Box>
                    <Box sx={{ flex: 0 }}>
                        <Carousel
                            visit={studyVisit}
                            selectedStudyId={studyId as string}
                            onClick={handleCarouselItemClick}
                        />
                    </Box>
                </>
            )}
        </Paper>
    );
};

export default memo(MediaViewer);
