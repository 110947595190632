import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import GridCellExpand from "../GridCellExpand";
import React from "react";
import { useEditCRFMutation, useRemoveCrfFieldsMutation } from "../dataAccess";
import { useSnackbar } from "notistack";

export const GridCell = (params: GridRenderCellParams<string>) => {
    const { enqueueSnackbar } = useSnackbar();
    const apiRef = useGridApiContext();
    const [removeCrfFields] = useRemoveCrfFieldsMutation();
    const [editCRF] = useEditCRFMutation();

    const handleRemove = () => {
        try {
            const rowValues = apiRef.current.getRow(params.id);
            const { visitId, patientId, laterality } = rowValues;

            if (params.field === "notes") {
                const payload = { laterality: laterality, visitId: visitId, notes: "" };
                // @ts-ignore
                return editCRF({ patientId: patientId, crfUpdate: payload }).unwrap();
            }

            return removeCrfFields({ visitId, patientId, laterality, fieldsToRemove: [params.field] }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };
    return (
        <GridCellExpand
            isEditable={Boolean(params.isEditable)}
            formattedValue={params.formattedValue as string}
            value={params.value || ""}
            onRemove={handleRemove}
            width={params.colDef.computedWidth}
        />
    );
};
