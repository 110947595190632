import { Card, CardActionArea, CardMedia, Checkbox, CardProps, Button, Skeleton, Popover, Chip } from "@mui/material";
import {
    Info as InfoIcon,
    Launch as LaunchIcon,
    Beenhere as BeenhereIcon,
    Visibility as VisibilityIcon,
    ContentCopy as ContentCopyIcon,
    DeleteOutline as DeleteOutlineIcon,
    Download as DownloadIcon,
    StickyNote2 as NoteIcon,
} from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { selectOrionSessionId } from "../../orion/orionSlice";
import { useLaunchOrionMutation, useCreateOrionSessionMutation } from "../../orion/dataAccess";
import { API_MEDIA_BASE_PATH } from "../../../lib/nebulaClient";
import { useHistory } from "react-router-dom";
import { figureFlavor, figureCardTitle } from "../../../utils/MediaUtils";
import { Box, styled } from "@mui/system";
import { IImagingProcedure, IMediaOctFile } from "../studiesSlice";
import { useDeleteStudyMutation, useDownloadStudyMutation, useEditNoteMutation } from "../dataAccess";
import { common, grey } from "@mui/material/colors";
import React, { useMemo, useState } from "react";
import DisplayText from "../../../components/DisplayText/DisplayText";
import SizedContainerPDF from "../../../components/PDF/SizedConteiner";
import LateralityPill from "./LateralityPill";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentPatient } from "../../patients/patientsSlice";
import queryString from "query-string";
import { getQueryParamFromStudyId } from "../../media/utils";
import MenuList from "../../../components/MenuList/MenuList";
import CopyCaseInfo from "../../media/CopyCaseInfo";
import usePermissions from "../../permissions/usePermissions";
import { useConfirmationModal } from "../../../components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import config from "../../../config.json";
import TextFromFile from "../../../components/TextFromFile/TextFromFile";
import CSVToTable from "../../../components/CSVToTable/CSVToTable";
import ReportEligibility from "../../media/ReportEligibility";
import ToolTip from "../../../components/Tooltip/Tooltip";
import NoteDialog from "./NoteDialog";

const CustomCard = styled(Card)<CardProps>(({ theme }) => ({
    backgroundColor: "2B323E",
    width: "100%",
}));

interface MediaProps {
    octFile: IMediaOctFile;
    visitMetadata: IImagingProcedure;
    onCheckboxSelect: (id: string[], isChecked: boolean) => void;
    selected: boolean;
    actionsDisable: boolean;
    token?: string;
    isLoading: boolean;
    error: boolean;
}

const OpenInOrionButton = styled(Button)(({ theme }) => ({
    "color": common.white,
    "border": `1px solid ${common.white}`,
    "&:hover": {
        borderColor: grey[300],
    },
}));

const ViewButton = styled(Button)(({ theme }) => ({
    "color": theme.palette.getContrastText(common.white),
    "backgroundColor": common.white,
    // "border": `1px solid ${common.white}`,
    "&:hover": {
        backgroundColor: grey[300],
    },
}));

const StudyCard: React.FC<MediaProps> = ({
    octFile,
    visitMetadata,
    onCheckboxSelect,
    selected,
    actionsDisable,
    token,
    isLoading,
    error,
}) => {
    const [copyQueryInfoOpen, setCopyQueryInfoOpen] = useState<boolean>(false);
    const [reportEligibilityOpen, setReportEligibilityOpen] = useState<boolean>(false);
    const cardTitle = figureCardTitle(octFile);
    const { hasPermissions } = usePermissions();
    const currentPatient = useAppSelector(selectCurrentPatient);
    const flavor = figureFlavor(octFile);

    const orionSessionId = useAppSelector(selectOrionSessionId);
    const history = useHistory();
    const [deleteStudy] = useDeleteStudyMutation();
    const { openConfirmationModal } = useConfirmationModal({
        title: "Are you sure you want to delete the study?",
        content: "You will no longer be able to acces the study.",
        acceptLabel: "Confirm",
    });

    const [launchOrion] = useLaunchOrionMutation();
    const [createOrionSession] = useCreateOrionSessionMutation();
    const [downloadStudy] = useDownloadStudyMutation();
    const [editNote] = useEditNoteMutation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const [noteDialogOpen, setNoteDialogOpen] = useState(false);

    const handleOpenNoteDialog = () => setNoteDialogOpen(true);

    const handleCloseNoteDialog = () => setNoteDialogOpen(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const onViewerClick = () => {
        // Adding random id before id as query params to be able to differentiate when having to media cards
        // with the same id. This is important for example when closing a card
        history.push({
            pathname: `/visits/${currentPatient!.uuid}`,
            search: queryString.stringify({ st: getQueryParamFromStudyId(octFile.uuid) }),
        });
    };

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        onCheckboxSelect([octFile.uuid], isChecked);
    };

    const getStudyUrL = (study: IMediaOctFile): string => {
        const fileExtension = study.fullUploadPath.split(".").pop();

        const kind = study.mimeType.label === "data/oct-image" ? "oct-image" : fileExtension;

        return `/media/stream/${kind}/${study.uuid}`;
    };

    const handleDownloadStudy = async () => {
        enqueueSnackbar("Download is being prepared...", {
            variant: "info",
            persist: true,
        });

        try {
            const studyUrl = getStudyUrL(octFile);

            const fileBlob = await downloadStudy(studyUrl).unwrap();

            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([fileBlob]));

            // Crete link element
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", octFile.FileName);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link!.parentNode!.removeChild(link);

            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("There was a problem with the download.", {
                variant: "error",
            });
        }
    };

    const handleDeleteStudy = () => {
        return openConfirmationModal(() => {
            deleteStudy(octFile.uuid);
        });
    };

    const handleOpenReportEligibility = () => {
        setReportEligibilityOpen(true);
    };

    const handleCloseReportEligibility = () => {
        setReportEligibilityOpen(false);
    };

    const handleOpenCopyCaseInfo = () => {
        setCopyQueryInfoOpen(true);
    };

    const handleCloseCopyCaseInfo = () => {
        setCopyQueryInfoOpen(false);
    };

    const handleEditNote = async (newNote: string) => {
        try {
            await editNote({
                mediaUuid: octFile.uuid,
                note: newNote,
                patientId: currentPatient!.uuid,
            }).unwrap();
            enqueueSnackbar("Note successfully edited.", { variant: "success" });
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
            throw e;
        }
    };

    const handleLaunchOrion = async () => {
        try {
            enqueueSnackbar("Opening file in Orion...", {
                variant: "info",
                persist: true,
            });

            const orionSession = await createOrionSession(orionSessionId).unwrap();

            setTimeout(() => {
                // @ts-ignore
                launchOrion({ sessionId: orionSession.id, studiesIds: [octFile.uuid] });
                closeSnackbar();
            }, 10000);

            const baseUrl = process.env.REACT_APP_NEBULA_SERVER_V1_URL || config.api_baseurl.orion;
            const host = baseUrl.replace("/api/v1/", "");
            // @ts-ignore
            const url = `vxorion://${host}?id=${orionSession.id}`;
            // @ts-ignore
            window.location.assign(url);
        } catch (e) {
            closeSnackbar();

            enqueueSnackbar("Something wrong happened", {
                variant: "error",
            });
        }
    };

    const cardHeight = "200px";
    const imageElement = () => {
        if (octFile) {
            switch (flavor) {
                case "attachment":
                    return (
                        <Box sx={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                            <AttachFileIcon height={64} width={64} />
                            <DisplayText type="bodyMedium" text={octFile.FileName} />
                        </Box>
                    );
                case "csv":
                    return (
                        <CSVToTable
                            style={{ borderRight: 0, borderLeft: 0 }}
                            url={`${API_MEDIA_BASE_PATH}/media/stream/csv/${octFile.uuid}`}
                        />
                    );
                case "mp4":
                case "oct-mp4":
                    return (
                        <Box>
                            <Box
                                sx={{
                                    zIndex: 999,
                                    position: "absolute",
                                    top: 6,
                                    left: 6,
                                    padding: 5,
                                    backgroundColor: "#0000003b",
                                    borderRadius: 2,
                                }}
                            >
                                <DisplayText type="bodyXSmall" text={`Quality: ${octFile.ImageQualityDisplayText}`} />
                            </Box>
                            <Box
                                sx={{
                                    zIndex: 999,
                                    position: "absolute",
                                    top: 6,
                                    right: 6,
                                    padding: 5,
                                    backgroundColor: "#0000003b",
                                    borderRadius: 2,
                                }}
                            >
                                <DisplayText type="bodyXSmall" text={`${octFile.NumSlices} Slices`} />
                            </Box>
                            <CardMedia
                                component="video"
                                src={`${API_MEDIA_BASE_PATH}/t/${token}/media/stream/mp4/${octFile.uuid}`}
                                controls={true}
                                sx={{
                                    height: cardHeight,
                                    objectFit: "fill",
                                }}
                            />
                        </Box>
                    );
                case "photo":
                case "oct-photo":
                    return (
                        <CardMedia
                            component="img"
                            onContextMenu={(e: React.MouseEvent<HTMLDivElement>) => {
                                e.preventDefault();
                            }}
                            alt={octFile.FileName}
                            image={`${API_MEDIA_BASE_PATH}/t/${token}/media/stream/jpeg/${octFile.uuid}`}
                            sx={{ height: cardHeight, pointerEvents: "none" }}
                        />
                    );
                case "pdf":
                    return (
                        <Box sx={{ height: "100%" }}>
                            <SizedContainerPDF
                                fileName={
                                    octFile.FileName ||
                                    `${octFile.Laterality ? `${octFile.Laterality} - ` : ""}${
                                        octFile.ScanDateAndTime
                                    }` ||
                                    "PDF File"
                                }
                                pdfUrl={`${API_MEDIA_BASE_PATH}/t/${token}/media/stream/pdf/${octFile.uuid}`}
                            />
                        </Box>
                    );

                case "text":
                    return (
                        <Box
                            sx={{
                                border: "1px solid rgba(81, 81, 81, 1)",
                                borderLeft: 0,
                                borderRight: 0,
                                height: "100%",
                                overflow: "auto",
                            }}
                        >
                            <Box sx={{ px: 14 }}>
                                <TextFromFile studyId={octFile.uuid} />
                            </Box>
                        </Box>
                    );
                default:
                    return null;
            }
        }
    };

    const menuListOptions = [
        ...(hasPermissions(["QC"])
            ? [{ label: "Query: Copy Case Info", Icon: <ContentCopyIcon />, onClick: handleOpenCopyCaseInfo }]
            : []),
        ...(hasPermissions(["QC"])
            ? [{ label: "Report Eligibility", Icon: <ContentCopyIcon />, onClick: handleOpenReportEligibility }]
            : []),
        ...(hasPermissions(["Download"])
            ? [
                  {
                      label: "Download",
                      Icon: <DownloadIcon />,
                      onClick: handleDownloadStudy,
                  },
              ]
            : []),
        ...(hasPermissions(["Delete"])
            ? [
                  {
                      label: "Remove",
                      Icon: <DeleteOutlineIcon color="error" />,
                      labelStyle: { color: "#f44336" },
                      onClick: handleDeleteStudy,
                  },
              ]
            : []),
    ];

    // i.e. if there are any IXD's associated with it
    const hasMediaBeenReadInOrion = useMemo(() => {
        return octFile.attachments.length > 0;
    }, [octFile]);

    if (!cardTitle || cardTitle === "hide") return null;

    if (error) {
        return <Box>En error occurred. Please try again.</Box>;
    }

    if (isLoading) {
        return (
            <CustomCard>
                <Box sx={{ py: 6, px: 9, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box>
                            <Skeleton variant="text" width={70} />
                            <Skeleton variant="text" width={120} height={20} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flex: 1,
                        }}
                    >
                        <Box sx={{ px: 9 }}>
                            <Skeleton variant="text" width={40} height={40} />
                        </Box>
                        <Box sx={{ display: "flex", gap: 10 }}>
                            <Skeleton variant="circular" width={16} height={16} />
                            <Skeleton variant="circular" width={16} height={16} />
                        </Box>
                    </Box>
                </Box>
                <CardActionArea>
                    <Skeleton sx={{ height: 200 }} animation="wave" variant="rectangular" />
                </CardActionArea>
                <Box sx={{ py: 6, px: 9 }}>
                    <Box sx={{ mb: 6 }}>
                        <Skeleton variant="text" width={120} />
                    </Box>
                    <Box sx={{ display: "flex", gap: 6 }}>
                        <Skeleton variant="text" width="100%" height={43} />
                        <Skeleton variant="text" width="100%" height={43} />
                    </Box>
                </Box>
            </CustomCard>
        );
    }

    const parseDateOfBirth = (rawDateOfBirth: string) => {
        const year = rawDateOfBirth.substring(0, 4);
        const month = rawDateOfBirth.substring(4, 6);
        const day = rawDateOfBirth.substring(6, 8);

        return `${year}-${month}-${day}`;
    };

    const showReadIcon = hasMediaBeenReadInOrion && hasPermissions(["Launch Orion"]);

    const NotificationComponent = octFile.Comment ? (
        <ToolTip content={<DisplayText type="bodyXSmall" text={octFile.Comment} />}>
            <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                <Chip
                    icon={<NoteIcon />}
                    onClick={handleOpenNoteDialog}
                    label="Notes"
                    size="small"
                    color={octFile.Comment ? "warning" : "default"}
                />
            </Box>
        </ToolTip>
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
            <Chip
                icon={<NoteIcon />}
                onClick={handleOpenNoteDialog}
                label="Add notes"
                size="small"
                color={octFile.Comment ? "warning" : "default"}
            />
        </Box>
    );

    return (
        <>
            <CopyCaseInfo
                siteName={visitMetadata.site}
                visitName={visitMetadata.visit || "Visit"}
                patientId={currentPatient!.patientId}
                isOpen={copyQueryInfoOpen}
                study={octFile}
                visitUuid={visitMetadata.visitUuid}
                onClose={handleCloseCopyCaseInfo}
            />
            <ReportEligibility
                siteName={visitMetadata.site}
                visitName={visitMetadata.visit || "Visit"}
                patientId={currentPatient!.patientId}
                isOpen={reportEligibilityOpen}
                study={octFile}
                visitUuid={visitMetadata.visitUuid}
                onClose={handleCloseReportEligibility}
            />

            <CustomCard data-test-id={`studyCard:${octFile.FileName}`}>
                <Box sx={{ py: 6, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox checked={selected} onChange={handleCheckboxClick} />
                        <Box>
                            <Box
                                style={{ cursor: "pointer" }}
                                onClick={() => onCheckboxSelect([octFile.uuid], !selected)}
                            >
                                <DisplayText type="bodyMedium" variant="semiBold" text={octFile.mimeType.prettyLabel} />
                            </Box>
                            <DisplayText type="bodyXSmall" variant="regular" text={octFile.ScanPattern} />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flex: 1,
                            flexDirection: "row-reverse",
                            px: 9,
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {showReadIcon && (
                                <ToolTip content={<DisplayText type="bodyXSmall" text="Read" />}>
                                    <Box sx={{ mr: 10, display: "flex", justifyContent: "center", cursor: "pointer" }}>
                                        <BeenhereIcon fontSize="small" sx={{ color: "#3babdc" }} />
                                    </Box>
                                </ToolTip>
                            )}
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                }}
                                onClick={handlePopoverOpen}
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                <InfoIcon fontSize="small" />
                            </Box>
                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: "none",
                                }}
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Box sx={{ p: 8, display: "flex", flexDirection: "column", gap: "4px" }}>
                                    <DisplayText type="bodyXSmall" text={`File name: ${octFile.FileName || "-"}`} />
                                    <DisplayText
                                        type="bodyXSmall"
                                        text={`Height: ${octFile.BscanHeightSizeInMicrons}μm`}
                                    />
                                    <DisplayText
                                        type="bodyXSmall"
                                        text={`Width: ${octFile.BscanWidthSizeInMicrons}μm`}
                                    />
                                    {octFile.originalDob && (
                                        <DisplayText
                                            type="bodyXSmall"
                                            text={`Date of birth: ${parseDateOfBirth(octFile.originalDob) || "-"}`}
                                        />
                                    )}
                                    {octFile.originalPatientFirstName && (
                                        <DisplayText
                                            type="bodyXSmall"
                                            text={`Patient first name: ${octFile.originalPatientFirstName || "-"}`}
                                        />
                                    )}
                                    {octFile.originalPatientLastName && (
                                        <DisplayText
                                            type="bodyXSmall"
                                            text={`Patient last name: ${octFile.originalPatientLastName || "-"}`}
                                        />
                                    )}
                                    <DisplayText
                                        type="bodyXSmall"
                                        text={`Upload: ${
                                            octFile.mediaDateAndTime
                                                ? new Date(octFile.mediaDateAndTime).toLocaleString()
                                                : "-"
                                        }`}
                                    />
                                </Box>
                            </Popover>
                            {menuListOptions.length > 0 ? (
                                <Box sx={{ mr: -9 }}>
                                    <MenuList options={menuListOptions} />
                                </Box>
                            ) : null}
                        </Box>
                        {octFile.Laterality ? (
                            <Box sx={{ pr: 9 }}>
                                <LateralityPill laterality={octFile.Laterality} />
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: "relative",
                        height: 200,
                        overflow: "auto",
                    }}
                >
                    {imageElement()}
                </Box>
                <Box sx={{ py: 6, px: 9 }}>
                    <Box sx={{ mb: 24, display: "flex", justifyContent: "space-between", alignItems: "initial" }}>
                        <DisplayText type="bodyXSmall" text={`Manufacturer: ${octFile.Manufacturer || "-"}`} />
                        {hasPermissions(["QC"]) ? NotificationComponent : null}
                    </Box>
                    <Box sx={{ display: "flex", gap: 6 }}>
                        {hasPermissions(["Launch Orion"]) && (flavor === "oct-mp4" || flavor === "oct-photo") ? (
                            <OpenInOrionButton
                                fullWidth
                                disabled={actionsDisable}
                                onClick={handleLaunchOrion}
                                size="small"
                                variant="outlined"
                                startIcon={<LaunchIcon />}
                            >
                                orion
                            </OpenInOrionButton>
                        ) : null}
                        <ViewButton
                            fullWidth
                            disabled={actionsDisable}
                            size="small"
                            variant="contained"
                            onClick={onViewerClick}
                            startIcon={<VisibilityIcon />}
                        >
                            view
                        </ViewButton>
                    </Box>
                </Box>
                <NoteDialog
                    initialNotes={octFile.Comment}
                    open={noteDialogOpen}
                    onClose={handleCloseNoteDialog}
                    onAccept={handleEditNote}
                />
            </CustomCard>
        </>
    );
};

export default StudyCard;
