import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateDerivedFile } from "../../uploadSlice";
import { Box } from "@mui/system";
import {
    Autocomplete,
    Button,
    CircularProgress,
    createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { IPatient } from "../../../patients/patientsSlice";
import { useGetPatientsQuery } from "../../../patients/dataAccess";

interface IEditablePatientProps {
    value: string;
    uuid: string;
}

const filter = createFilterOptions<any>();

interface IPatientOptionType {
    inputValue?: string;
    patientName: string;
    patientId: string;
}

const EditablePatient: React.FC<IEditablePatientProps> = ({ value, uuid }) => {
    const dispatch = useDispatch();

    const [newPatientDialogOpen, setNewPatientDialogOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        patientId: "",
        patientName: "",
    });
    const [isEditableVisible, setIsEditableVisible] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { data: patients, isLoading: patientsLoading } = useGetPatientsQuery(undefined, {
        skip: !open,
    });

    const patientOptions: IPatientOptionType[] = useMemo(() => {
        if (!patients) return [];

        return patients.map((patient: IPatient) => ({
            patientName: patient.patientId,
            patientId: patient.patientId,
        }));
    }, [patients]);

    const handleCloseNewPatientDialog = () => {
        setDialogValue({
            patientId: "",
            patientName: "",
        });
        setNewPatientDialogOpen(false);
    };

    const handleHover = () => setIsEditableVisible(true);

    const handleMouseLeave = () => setIsEditableVisible(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleFinishEdit = () => {
        setIsEditing(false);
    };

    const handleCreateNewPatient = (e: any) => {
        e.preventDefault();
        dispatch(updateDerivedFile({ id: uuid, changes: { patientId: dialogValue.patientId } }));
        setNewPatientDialogOpen(false);
        setIsEditableVisible(false);
        setIsEditing(false);
    };

    const handlePatientChange = (newPatient: any) => {
        if (newPatient.inputValue) {
            setDialogValue({
                ...dialogValue,
                patientName: newPatient.inputValue,
            });
            return setNewPatientDialogOpen(true);
        } else {
            setIsEditing(false);
            setIsEditableVisible(false);
            return dispatch(updateDerivedFile({ id: uuid, changes: { patientId: newPatient.patientId } }));
        }
    };

    return (
        <>
            <Box
                onMouseOver={handleHover}
                onMouseLeave={handleMouseLeave}
                sx={{ flex: 1, display: "flex", alignItems: "center" }}
            >
                {isEditing ? (
                    <>
                        <Autocomplete
                            value={null}
                            sx={{ width: 300 }}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.patientName === value.patientName}
                            getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                    return option;
                                }

                                return option.patientName;
                            }}
                            options={patientOptions}
                            loading={patientsLoading}
                            onChange={(e, value) => {
                                handlePatientChange(value);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                if (params.inputValue !== "") {
                                    filtered.push({
                                        inputValue: params.inputValue,
                                        patientName: `Add new patient "${params.inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Patients"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {patientsLoading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <IconButton
                            onClick={handleFinishEdit}
                            size="small"
                            color="primary"
                            aria-label="edit"
                            component="span"
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        <Dialog open={newPatientDialogOpen} onClose={handleCloseNewPatientDialog}>
                            <form onSubmit={handleCreateNewPatient}>
                                <DialogTitle>Add a new patient</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>Fill the information to create a new patient.</DialogContentText>
                                    <Box sx={{ mx: -8 }}>
                                        <Box sx={{ mx: 8 }}>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                value={dialogValue.patientId}
                                                onChange={(event) =>
                                                    setDialogValue({
                                                        ...dialogValue,
                                                        patientId: event.target.value,
                                                    })
                                                }
                                                label="Patient ID"
                                                type="text"
                                                variant="standard"
                                            />
                                        </Box>
                                        <Box sx={{ mx: 8 }}>
                                            <TextField
                                                margin="dense"
                                                id="name"
                                                value={dialogValue.patientName}
                                                onChange={(event) =>
                                                    setDialogValue({
                                                        ...dialogValue,
                                                        patientName: event.target.value,
                                                    })
                                                }
                                                label="Patient Name"
                                                variant="standard"
                                            />
                                        </Box>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button variant="outlined" onClick={handleCloseNewPatientDialog}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" type="submit">
                                        Create new patient
                                    </Button>
                                </DialogActions>
                            </form>
                        </Dialog>
                    </>
                ) : (
                    <>
                        <Typography>{value || "-"}</Typography>
                        {isEditableVisible ? (
                            <IconButton
                                onClick={handleEdit}
                                size="small"
                                color="primary"
                                aria-label="edit"
                                component="span"
                            >
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        ) : null}
                    </>
                )}
            </Box>
        </>
    );
};

export default EditablePatient;
