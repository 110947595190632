import { CrfField } from "../nebula.service";
import CustomTooltip from "../../../components/Tooltip/Tooltip";
import DisplayText from "../../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import React from "react";

export const ColumnHeader = (props: { field: CrfField }) => {
    return (
        <CustomTooltip
            content={<DisplayText type="bodyXSmall" text={props.field.description || ""} />}
            style={{ overflow: "hidden" }}
        >
            <Box sx={{ display: "flex", alignItems: "baseline", overflow: "hidden", gap: 5 }}>
                <DisplayText
                    style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                    type="bodySmall"
                    text={props.field.fieldName}
                />
                <DisplayText type="bodyXSmall" text={`(${props.field.laterality})`} />
            </Box>
        </CustomTooltip>
    );
};
