import { useDispatch } from "react-redux";
import React, { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { FileEntryStatus, IDerivedFile, IFileEntry, updateMultipleFileEntries } from "../../uploadSlice";
import { useGetUploadTrackQuery } from "../../dataAccess";
import FileEntry from "../FileEntry";

interface IFileEntriesSessionProps {
    sessionUuid: string;
    fileEntries: IFileEntry[];
}

const FileEntriesSession: React.FC<IFileEntriesSessionProps> = ({ sessionUuid, fileEntries }: any) => {
    const dispatch = useDispatch();

    const hasDerivedFileFailed = (derivedFile: IDerivedFile) => derivedFile.statusCode > 300;
    const hasDerivedFileSucceeded = (derivedFile: IDerivedFile) => derivedFile.statusCode === 200;

    const skipPollSessionStatus = useMemo(() => {
        const fileEntriesAreBeingProcessed = fileEntries.some(
            (fileEntry: IFileEntry) => fileEntry.status === FileEntryStatus.PROCESSING
        );

        const fileEntriesAreBeingUploaded = fileEntries.some(
            (fileEntry: IFileEntry) => fileEntry.status === FileEntryStatus.UPLOADING
        );

        if (fileEntriesAreBeingUploaded) return true;

        if (fileEntriesAreBeingProcessed) {
            return false;
        }

        return true;
    }, [fileEntries]);

    const { data } = useGetUploadTrackQuery(sessionUuid, { pollingInterval: 2000, skip: skipPollSessionStatus });

    useEffect(() => {
        if (!data || data.length === 0) return;

        const areFilesFinishedProcessing = data.every(
            (derivedFile: IDerivedFile) => hasDerivedFileSucceeded(derivedFile) || hasDerivedFileFailed(derivedFile)
        );

        if (areFilesFinishedProcessing) {
            const updatedFileEntries = fileEntries.map((fileEntry: IFileEntry, i: number) => {
                const isFailedEntry = data.some(hasDerivedFileFailed);

                if (isFailedEntry) {
                    const failedDerivedFiles = data.filter(hasDerivedFileFailed);
                    const errorMessage = failedDerivedFiles.reduce((acc: string[], derivedFile: IDerivedFile) => {
                        return [...acc, derivedFile.status];
                    }, []);

                    return {
                        id: fileEntry.uuid,
                        changes: {
                            status: FileEntryStatus.FAILED,
                            errorMessage,
                        },
                    };
                }

                return {
                    id: fileEntry.uuid,
                    changes: {
                        status: FileEntryStatus.PROCESSED,
                    },
                };
            });

            dispatch(updateMultipleFileEntries(updatedFileEntries));
        }
    }, [data]);

    return fileEntries.map((fileEntry: any) => (
        <Box key={fileEntry.uuid} sx={{ my: 4 }}>
            <FileEntry fileEntryId={fileEntry.uuid} />
        </Box>
    ));
};

export default FileEntriesSession;
