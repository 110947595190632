import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { AppDispatch, RootState } from "../../../app/store";
import Cookies from "js-cookie";
import { serviceSlice } from "../../../app/serviceSlice";
import { authService } from "../nebula.port";

const LOCAL_STORAGE_USER_DATA_KEY = "userCredentials";

export interface ILoginInfo {
    providerID: string;
    providerKey: string;
}

export interface IUser {
    userID: string;
    loginInfo: ILoginInfo;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    avatarURL: string;
    _activeOrgUuid: string;
    activated: boolean;
    adminFeatures: string;
    accessDate: string;
    accessExpires: string;
    accessOK: boolean;
    orgUuid: string;
    topOrgUuid: string;
    activeOrgUuid: string;
    projectOrgUuid: string;
    consentUuid: string;
}

export interface LoginState {
    isLoggedIn: boolean;
    email: string;
    status: "idle" | "success" | "loading" | "failed";
    errorMessage?: string;
}

const checkIfUserIsAlreadyLoggedIn = () => {
    const userCredentials = localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY);

    if (userCredentials) {
        const parsedData = JSON.parse(userCredentials);

        const decodedToken: JwtPayload = jwt_decode(parsedData.token);

        if (!decodedToken) return false;

        const expirationDate = (decodedToken.exp as number) * 1000;
        const currentDate = Date.now();

        return expirationDate > currentDate;
    }

    return false;
};

export const clearState = createAction<void>("store/clearState");

export const logoutUser = () => async (dispatch: AppDispatch) => {
    await Cookies.remove("PLAY_CSRF_TOKEN");

    // remove user info from local storage
    await localStorage.removeItem(LOCAL_STORAGE_USER_DATA_KEY);

    // Clear all cached api requests
    serviceSlice.util.resetApiState();
    // reset state
    dispatch(clearState());
};

export const loginUser = createAsyncThunk(
    "auth/loginUser",
    async (credentials: { email: string; password: string }) => {
        // remove all cookie in case it exists
        Cookies.remove("PLAY_CSRF_TOKEN");

        const response = await authService.login(credentials);

        if (response.error) throw Error("Unable to authenticate");

        const authToken = response.data;

        // save user data to future use in other requests that need authentication
        localStorage.setItem(
            LOCAL_STORAGE_USER_DATA_KEY,
            JSON.stringify({ userId: credentials.email, token: authToken })
        );

        return response.data;
    }
);

export const getInitialState = () => {
    const userIsLoggedIn = checkIfUserIsAlreadyLoggedIn();

    return {
        isLoggedIn: userIsLoggedIn,
        email: "",
        status: "idle",
        errorMessage: "",
    };
};

export const loginSlice = createSlice({
    name: "userInfo",
    initialState: getInitialState,
    reducers: {
        logout: (state) => {
            state.isLoggedIn = false;
            state.email = "";
            state.status = "idle";
            state.errorMessage = "";
        },
    },
    extraReducers(builder) {
        builder.addCase(loginUser.pending, (state) => {
            state.status = "loading";
            state.errorMessage = "";
        });
        builder.addCase(loginUser.fulfilled, (state) => {
            state.status = "success";
            state.isLoggedIn = true;
            state.errorMessage = "";
        });
        builder.addCase(loginUser.rejected, (state) => {
            state.status = "failed";
            state.isLoggedIn = false;
            state.errorMessage =
                "Unrecognized username or password. Please check and try again. If you continue to have a problem please contact your system administrator first";
        });
    },
});

export const { logout } = loginSlice.actions;

export const selectUserInfo = (state: RootState) => {
    return state.userInfo;
};

export const isUserLoggedIn = (state: RootState) => state.userInfo.isLoggedIn;

export default loginSlice.reducer;
