import React, { useCallback, useMemo } from "react";
import {
    IPatient,
    selectPatient,
    sortPatients,
    deselectPatient,
    selectPatientSort,
    Filter,
    selectCurrentFiltersConfig,
    selectCurrentPatient,
} from "./patientsSlice";
import { useGetPatientsQuery } from "./dataAccess";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    TableRowProps,
    TableContainer,
    ButtonBase,
    CircularProgress,
} from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import theme from "../../app/theme";
import SearchBar from "./SearchBar";
import { Box } from "@mui/system";
import { useUpdateCurrentGroupMutation } from "../upload/dataAccess";
import DisplayText from "../../components/DisplayText/DisplayText";
import usePermissions from "../permissions/usePermissions";

type PatientCellProps = {
    width?: string;
};

const PatientCell = styled(TableCell)<PatientCellProps>(({ theme, width }) => ({
    borderBottom: "none",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width,
    padding: theme.spacing(5),
    height: theme.spacing(1),
    fontSize: theme.spacing(12),
}));

const PatientRow = styled(TableRow)<TableRowProps>(() => ({
    "borderBottom": "none",
    "&:hover": {
        cursor: "pointer",
    },
}));

const PatientsDashboard = () => {
    const patientSort = useAppSelector(selectPatientSort);
    const currentPatient = useAppSelector(selectCurrentPatient);
    const currentFilter = useAppSelector(selectCurrentFiltersConfig);
    const { hasPermissions } = usePermissions();
    const dispatch = useAppDispatch();

    const [searchQuery, setSearchQuery] = React.useState<string>("");
    const {
        data: patients,
        isLoading: patientsLoading,
        isFetching: patientsFetching,
    } = useGetPatientsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    const [, { isLoading: isChangingGroup }] = useUpdateCurrentGroupMutation({ fixedCacheKey: "groupChange" });

    const handlePatientClick = (event: any, patient: IPatient) => {
        event.preventDefault();
        if (currentPatient?.uuid === patient["uuid"]) {
            dispatch(deselectPatient());
        } else {
            dispatch(selectPatient({ currentPatient: patient }));
        }
    };

    const handleClick = (event: any, field: keyof IPatient) => {
        const newOrder = patientSort.order === "desc" ? "asc" : "desc";
        dispatch(sortPatients({ field, order: newOrder }));
    };

    const getFilteredPatients = (patients: IPatient[], filters: Filter[]) => {
        if (filters.length === 0) return patients;

        const filterFunctions = Object.values(filters).map(({ onFilter }) => onFilter);

        const filteredPatients = filterFunctions.reduce((acc, filter) => {
            return patients.filter(filter);
        }, patients);

        return filteredPatients;
    };

    const patientsLocalList = React.useMemo(() => {
        if (!patients) return;

        const filteredList = getFilteredPatients(patients, currentFilter);

        let orderedList;
        if (patientSort.order === "desc") {
            orderedList = [...filteredList].sort((patient1, patient2) => {
                if (patient1[patientSort.field] > patient2[patientSort.field]) return 1;
                if (patient1[patientSort.field] < patient2[patientSort.field]) return -1;
                else return 0;
            });
        } else {
            orderedList = [...filteredList].sort((patient1, patient2) => {
                if (patient1[patientSort.field] < patient2[patientSort.field]) return 1;
                if (patient1[patientSort.field] > patient2[patientSort.field]) return -1;
                else return 0;
            });
        }

        return orderedList.filter(
            (patient) =>
                patient.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                patient.patientId.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [patientSort, patients, searchQuery, currentFilter]);

    const tableIsLoading = useMemo(() => {
        return patientsLoading || patientsFetching || isChangingGroup;
    }, [patientsLoading, patientsFetching, isChangingGroup]);

    const hasPatients = useMemo(() => {
        return patientsLocalList && patientsLocalList.length > 0;
    }, [patientsLocalList]);

    const getPatientVisitDate = useCallback(
        (patientDTO: IPatient) => {
            if (!patientsLocalList) return "";

            if (patientDTO["earliestISODate"] && patientDTO["latestISODate"]) {
                return `${patientDTO["earliestISODate"]} ~ ${patientDTO["latestISODate"]}`;
            }

            return patientDTO["earliestISODate"];
        },
        [patientsLocalList]
    );

    return (
        <React.Fragment>
            <TableContainer sx={{ padding: theme.spacing(20) }}>
                <SearchBar onChange={setSearchQuery} value={searchQuery} />
                <Table size="small" sx={{ tableLayout: "fixed" }}>
                    <TableHead>
                        <TableRow>
                            {/*<PatientCell align="left">*/}
                            {/*    <ButtonBase>Status</ButtonBase>*/}
                            {/*</PatientCell>*/}
                            {hasPermissions(["QC"]) ? (
                                <PatientCell width="60px" align="left">
                                    <ButtonBase
                                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, "isQc")}
                                    >
                                        Status
                                        {patientSort.field === "isQc" ? (
                                            patientSort.order === "desc" ? (
                                                <ArrowDropDown />
                                            ) : (
                                                <ArrowDropUp />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </ButtonBase>
                                </PatientCell>
                            ) : null}
                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, "patientId")}
                                >
                                    Subject #
                                    {patientSort.field === "patientId" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>

                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                                        handleClick(event, "patientName")
                                    }
                                >
                                    Name
                                    {patientSort.field === "patientName" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>
                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, "site")}
                                >
                                    Site Name
                                    {patientSort.field === "site" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>
                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, "gender")}
                                >
                                    Gender
                                    {patientSort.field === "gender" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>
                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                                        handleClick(event, "dateOfBirth")
                                    }
                                >
                                    DOB
                                    {patientSort.field === "dateOfBirth" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>
                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) => handleClick(event, "visits")}
                                >
                                    Visit(s)
                                    {patientSort.field === "visits" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>
                            <PatientCell align="left">
                                <ButtonBase
                                    onClick={(event: React.MouseEvent<HTMLElement>) =>
                                        handleClick(event, "latestISODate")
                                    }
                                >
                                    Visit date(s)
                                    {patientSort.field === "latestISODate" ? (
                                        patientSort.order === "desc" ? (
                                            <ArrowDropDown />
                                        ) : (
                                            <ArrowDropUp />
                                        )
                                    ) : (
                                        ""
                                    )}
                                </ButtonBase>
                            </PatientCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableIsLoading ? (
                            <tr>
                                <td>
                                    <Box
                                        sx={{
                                            mr: 16,
                                            position: "absolute",
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CircularProgress size={32} />
                                    </Box>
                                </td>
                            </tr>
                        ) : hasPatients ? (
                            patientsLocalList!.map((patientDTO) => (
                                <PatientRow
                                    key={patientDTO["uuid"]}
                                    onClick={(event: any) => handlePatientClick(event, patientDTO)}
                                    hover
                                    data-test-id={`patientRow:${patientDTO["patientId"]}`}
                                    selected={patientDTO.uuid === currentPatient?.uuid}
                                    sx={
                                        patientDTO["uuid"] === currentPatient?.uuid
                                            ? {
                                                  bgcolor: theme.palette.background.paper,
                                              }
                                            : {}
                                    }
                                >
                                    {/*<PatientCell align="center">*/}
                                    {/*    <Avatar*/}
                                    {/*        aria-label="label"*/}
                                    {/*        sx={{*/}
                                    {/*            backgroundColor: theme.palette.success.main,*/}
                                    {/*            height: theme.spacing(20),*/}
                                    {/*            width: theme.spacing(20),*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <Typography*/}
                                    {/*            sx={{*/}
                                    {/*                fontSize: theme.spacing(10),*/}
                                    {/*                fontWeight: 1000,*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            OK*/}
                                    {/*        </Typography>*/}
                                    {/*    </Avatar>*/}
                                    {/*</PatientCell>*/}
                                    {hasPermissions(["QC"]) ? (
                                        <PatientCell>{patientDTO["isQc"] ? "QC" : ""}</PatientCell>
                                    ) : null}
                                    <PatientCell>{patientDTO["patientId"]}</PatientCell>
                                    <PatientCell>{patientDTO["patientName"]}</PatientCell>
                                    <PatientCell>{patientDTO["site"]}</PatientCell>
                                    <PatientCell>{patientDTO["gender"]}</PatientCell>
                                    <PatientCell>{patientDTO["dateOfBirth"]}</PatientCell>
                                    <PatientCell>{patientDTO["visits"]}</PatientCell>
                                    <PatientCell>{getPatientVisitDate(patientDTO)}</PatientCell>
                                </PatientRow>
                            ))
                        ) : null}
                    </TableBody>
                </Table>
            </TableContainer>
            {hasPatients || tableIsLoading ? null : (
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <DisplayText text="No patients found" type="bodyLarge" />
                </Box>
            )}
        </React.Fragment>
    );
};

export default PatientsDashboard;
