import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { IMediaOctFile, IStudy } from "../studies/studiesSlice";
import { Divider } from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";

interface IAddStudyDialogProps {
    onClose: () => void;
    onAddStudy: (studyId: string) => void;
    open: boolean;
    studies: IStudy[];
}

const AddStudyDialog: React.FC<IAddStudyDialogProps> = ({ onClose, open, studies, onAddStudy }) => {
    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value: string) => {
        onAddStudy(value);
    };

    const renderStudy = (study: IMediaOctFile) => (
        <ListItem sx={{ pl: 20 }} button onClick={() => handleListItemClick(study.uuid)} key={study.uuid}>
            <ListItemText
                primary={`${study.mimeType.prettyLabel} ${study.Laterality ? `- ${study.Laterality}` : ""}`}
                secondary={`Slices: ${study.NumSlices}`}
            />
        </ListItem>
    );

    const renderVisit = (visit: IStudy) => (
        <Box key={visit.imagingProcedure.uuid}>
            <Divider />
            <li style={{ paddingLeft: 25 }} key={visit.imagingProcedure.uuid}>
                <ul>
                    <DisplayText
                        text={`Date: ${new Date(visit.imagingProcedure.orderDate).toLocaleDateString()}`}
                        type="bodyMedium"
                        variant="semiBold"
                        style={{ marginTop: 8 }}
                    />
                    {visit.mediaOctFile.map(renderStudy)}
                </ul>
            </li>
        </Box>
    );

    return (
        <Dialog fullWidth maxWidth="sm" scroll="paper" onClose={handleClose} open={open}>
            <DialogTitle>Add Study</DialogTitle>
            <List subheader={<li />}>{studies.map(renderVisit)}</List>
        </Dialog>
    );
};

export default AddStudyDialog;
