import React, { useMemo } from "react";
import { Box, Divider, IconButton, Drawer, CircularProgress } from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { HighlightOff as HighlightOffIcon } from "@mui/icons-material";
import { CRFField, UserCRF } from "../studies/studiesSlice";
import CRFVisitTable from "../crf/CRFTable";
import { IPatient } from "../patients/patientsSlice";
import { useGetPatientCrfQuery } from "../crf/dataAccess";
import { CrfVisit } from "../crf/nebula.service";

interface ICRFDrawerProps {
    isOpen: boolean;
    title: string;
    onClose: () => void;
    patient: IPatient;
    selectedVisitId: string;
}

const CRFDrawer: React.FC<ICRFDrawerProps> = ({ isOpen, onClose, title, patient, selectedVisitId }) => {
    const { data, error } = useGetPatientCrfQuery(patient.uuid, { skip: !isOpen });

    const crfForVisit = useMemo(() => {
        if (!data || !selectedVisitId) return null;

        const crfForVisit = data.visits.find((crfVisit) => crfVisit.uuid === selectedVisitId) as CrfVisit;

        return {
            data: crfForVisit.data,
            fields: crfForVisit.fields,
            visit: {
                uuid: crfForVisit.uuid,
                name: crfForVisit.visitName,
            },
        };
    }, [data, selectedVisitId]);

    return (
        <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
            <Box sx={{ p: 16, backgroundColor: "#202735" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 16 }}>
                    <DisplayText type="h6" text={title} />
                    <IconButton size="small" onClick={onClose}>
                        <HighlightOffIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Divider />
                {error ? (
                    <div>An error occurred</div>
                ) : !crfForVisit ? (
                    <Box sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <CRFVisitTable
                        patient={patient}
                        fields={crfForVisit!.fields}
                        data={crfForVisit!.data}
                        visit={crfForVisit!.visit}
                    />
                )}
            </Box>
        </Drawer>
    );
};

export default CRFDrawer;
