import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface IImagingProcedure {
    orderDate: string;
    encounterUuid: string;
    encounterIdentifier: string;
    visit?: string;
    visitUuid: string;
    procedureIdentifier: string;
    site: string;
    trialName: string;
    studyUid: string;
    identifierType: string;
    reason: string;
    uuid: string;
}
export interface MimeType {
    label: string;
    prettyLabel: string;
}

// //////////////////////////////////////////
//  ####   #####   ######
// #    #  #    #  #
// #       #    #  #####
// #       #####   #
// #    #  #   #   #
//  ####   #    #  #
// //////////////////////////////////////////

type ReaderId = string;
export type EyeLaterality = "os" | "od";

export interface FieldOption {
    label: string;
    value?: number | string | null | undefined;
    type?: "input";
    unit?: string;
    inputType?: "number" | "float" | "string";
}

export interface CRFRow {
    fieldName: string;
    fieldValue: number | string | null | undefined;
}

export interface CRFField {
    fieldName: string;
    description: string;
    fieldType: "List" | "String";
    isEditable?: boolean;
    fieldOptions?: FieldOption[];
}

type CRFReaderData = {
    os: CRFRow[];
    od: CRFRow[];
};

export type StudyCRF = Record<ReaderId, CRFReaderData>;

export type UserCRF = {
    formUri: string;
    encounterUuid: string;
    encounterIdentifier: string;
    uuid: string;
    studyUuid: string;
    isReadOnly: boolean;
    userName: string;
    updatedOn: string;
    identifierType: string;
    encounterDate: string;
    identifierUuid: string;
    os: CRFRow[];
    od: CRFRow[];
};

export type CRFStudyData = {
    fields: CRFField[];
    crfs: UserCRF[];
};

export interface ICRFData {
    crfs: UserCRF[];
    fields: CRFField[];
}
// /////////////////////////////////////////////////////////
export interface IMediaOctFile {
    attachments: string[];
    mediaDateAndTime: string;
    originalDob: string;
    originalPatientId: string;
    originalPatientFirstName: string;
    originalPatientLastName: string;
    trialName: string;
    fullUploadPath: string;
    uuid: string;
    mimeType: MimeType;
    ScanDateAndTime: string;
    ScanPattern: string;
    Laterality: string;
    Manufacturer: string;
    Device: string;
    FileName: string;
    ImageQuality: number;
    ImageQualityDisplayText: string;
    RasterAngleInDeg: number;
    FirstBscanStartXInMm: number;
    FirstBscanStartYInMm: number;
    LastBscanStartXInMm: number;
    LastBscanStartYInMm: number;
    BscanHeightDim: number;
    NumSlices: number;
    BscanWidthSizeInMicrons: number;
    BscanHeightSizeInMicrons: number;
    NumSlicesSizeInMicrons: number;
    UniqueId: string;
    SiteId: string;
    SeriesId: number;
    Comment: string;
    visit: string;
    ThumbNailFileName: string;
}

export interface IStudy {
    imagingProcedure: IImagingProcedure;
    mediaOctFile: IMediaOctFile[];
}

const studiesAdapter = createEntityAdapter<IStudy>({
    selectId: (study) => study.imagingProcedure.uuid,
});

export const emptyStudy: IStudy = {
    imagingProcedure: {
        visit: "",
        encounterUuid: "",
        encounterIdentifier: "",
        visitUuid: "",
        orderDate: "",
        procedureIdentifier: "",
        site: "",
        trialName: "",
        studyUid: "",
        identifierType: "",
        reason: "",
        uuid: "",
    },
    mediaOctFile: [],
};

export interface StudyState {
    studyList: EntityState<IStudy>;
    currentStudy: IStudy | null;
    errorMessage?: string;
    token?: string;
}

export const initialState: StudyState = {
    studyList: studiesAdapter.getInitialState(),
    currentStudy: null,
};

export const studySlice = createSlice({
    name: "studies",
    initialState,
    reducers: {
        studySelected: (state, action: PayloadAction<IStudy>) => {
            state.currentStudy = action.payload;
        },
    },
});

export const selectStudies = (state: RootState) => {
    return state.studies;
};

export const { selectById: selectStudyById, selectAll: selectAllStudies } = studiesAdapter.getSelectors<RootState>(
    (state) => state.studies.studyList
);

export const { studySelected } = studySlice.actions;

export default studySlice.reducer;
