import React, { useEffect, useMemo, useRef, useState } from "react";
import DisplayText from "../../components/DisplayText/DisplayText";
import { IconButton, Popper } from "@mui/material";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import theme from "../../app/theme";

interface GridCellExpandProps {
    value: string;
    width: number;
    formattedValue: string;
    onRemove: () => void;
    isEditable: Boolean;
}

function isOverflown(element: Element): boolean {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const GridCellExpand: React.FC<GridCellExpandProps> = ({ formattedValue, width, value, onRemove, isEditable }) => {
    const wrapper = useRef<HTMLDivElement | null>(null);
    const cellDiv = useRef(null);
    const cellValue = useRef(null);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showFullCell, setShowFullCell] = useState(false);

    const handleMouseEnter = () => {
        setAnchorEl(cellDiv.current);
        setIsMouseOver(true);
        setShowFullCell(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
        setShowFullCell(false);
    };

    const showCloseButton = useMemo(() => isEditable && value && isMouseOver, [isEditable, value, isMouseOver]);
    const showValuePopper = useMemo(() => isMouseOver && isOverflown(cellValue.current!), [cellValue, isMouseOver]);

    useEffect(() => {
        if (!showFullCell) {
            return undefined;
        }

        function handleKeyDown(nativeEvent: KeyboardEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
                setShowFullCell(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);

    return (
        <Box
            ref={wrapper}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{
                alignItems: "center",
                lineHeight: "24px",
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box
                ref={cellDiv}
                sx={{
                    height: "100%",
                    display: "block",
                    position: "absolute",
                    top: -5,
                    left: 10,
                }}
            />
            <Box ref={cellValue} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {formattedValue}
            </Box>
            {showCloseButton ? (
                <IconButton onClick={onRemove} size="small">
                    <Close
                        sx={{
                            height: theme.spacing(15),
                            width: theme.spacing(15),
                            color: "disable",
                        }}
                    />
                </IconButton>
            ) : null}

            {showValuePopper ? (
                // @ts-ignore
                <Popper
                    open={showFullCell && anchorEl !== null}
                    anchorEl={anchorEl}
                    style={{
                        zIndex: 99999,
                        backgroundColor: "#1e2735",
                        borderRadius: "4px",
                        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                        padding: 8,
                        offset: "2px",
                        overflowWrap: "break-word",
                    }}
                >
                    <Box>
                        <DisplayText type="bodyXSmall" text={formattedValue} />
                    </Box>
                </Popper>
            ) : null}
        </Box>
    );
};

export default React.memo(GridCellExpand);
