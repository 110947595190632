import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import { memo } from "react";

interface IImageMediaViewerProps {
    uuid: string;
    token: string;
}
const ImageMediaViewer: React.FC<IImageMediaViewerProps> = ({ uuid, token }) => {
    const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <img
            onContextMenu={handleMouseEvent}
            alt="study"
            style={{ height: "100%", width: "100%", objectFit: "contain", pointerEvents: "none" }}
            src={`${API_MEDIA_BASE_PATH}/t/${token}/media/stream/jpeg/${uuid}`}
        />
    );
};

export default memo(ImageMediaViewer);
