import { serviceSlice } from "../../app/serviceSlice";
import { mediaService } from "./nebula.port";
import { IUser } from "../auth/login/loginSlice";
import { IAnalysis, IApiResult, IEnFaceInfo, IMediaInfo } from "./viewerSlice";

const extendedApiSlice = serviceSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEnFaceInfo: builder.query<IEnFaceInfo, string>({
            query: (mediaId) => () => mediaService.getEnFaceInfo(mediaId),
        }),
        getAnalysis: builder.query<IAnalysis[], string>({
            query: (studyId) => () => mediaService.getAnalysis(studyId),
            transformResponse(rawResult: IApiResult<IAnalysis[]>) {
                return rawResult.data;
            },
        }),
        getMediaInfo: builder.query<IMediaInfo, string>({
            query: (mediaId) => () => mediaService.getMediaInfo(mediaId),
        }),
        getSiteUsers: builder.query<IUser[], string>({
            query: (visitUuid) => () => mediaService.getSiteUsers(visitUuid),
        }),
        getTextFile: builder.query<string, string>({
            query: (studyId) => () => mediaService.getTextFile(studyId),
            transformResponse: async (rawResult: Blob) => {
                const text = await rawResult.text();
                return text;
            },
        }),
    }),
});

export const {
    useGetEnFaceInfoQuery,
    useGetAnalysisQuery,
    useGetMediaInfoQuery,
    useGetSiteUsersQuery,
    useGetTextFileQuery,
} = extendedApiSlice;
