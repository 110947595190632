import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { getHeadersWithToken } from "../../lib/nebulaClient";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { nanoid } from "@reduxjs/toolkit";
import DisplayText from "../DisplayText/DisplayText";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

interface ICSVToTableProps {
    url: string;
    style?: object;
}

const CSVToTable: React.FC<ICSVToTableProps> = ({ url, style = {} }) => {
    const [colDefs, setColDefs] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<Record<string, any>[]>([]);
    const [csvLoadingState, setCsvLoadingState] = useState({ loading: true, error: "" });

    const convertToJson = (headers: string[], data: [string[]]) => {
        const rows: Record<string, string>[] = [];
        data.forEach((row) => {
            let rowData: Record<string, any> = { id: nanoid() };
            row.forEach((element, index) => {
                rowData[`${index}`] = element;
            });
            rows.push(rowData);
        });
        return rows;
    };

    const loadCSV = () => {
        try {
            // @ts-ignore
            Papa.parse(url, {
                download: true,
                downloadRequestHeaders: getHeadersWithToken(),
                complete: function (results: { data: [string[]] }) {
                    const fileData = results.data;
                    // console.log(fileData)
                    const headers = fileData[0];
                    const heads = headers.map((head: string, i) => ({
                        headerName: head,
                        field: `${i}`,
                        flex: 1,
                        sortable: false,
                        minWidth: 80,
                    }));
                    setColDefs(heads);

                    //removing header
                    fileData.splice(0, 1);

                    setRows(convertToJson(headers, fileData));

                    setCsvLoadingState({ loading: false, error: "" });
                },
            });
        } catch (e) {
            setCsvLoadingState({ loading: false, error: "Something wrong happened." });
        }
    };

    useEffect(() => {
        loadCSV();
    }, []);

    const ErrorFallback = () => <DisplayText type="bodyMedium" text="Something wrong happened." />;

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            <DataGrid
                style={style}
                loading={csvLoadingState.loading}
                disableColumnFilter
                disableSelectionOnClick
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                density="compact"
                hideFooter
                rows={rows}
                columns={colDefs}
            />
        </ErrorBoundary>
    );
};

export default CSVToTable;
