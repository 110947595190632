import React, { BaseSyntheticEvent, useState, Fragment } from "react";
import { FilterList, Search as SearchIcon } from "@mui/icons-material";
import { Menu, IconButton, InputBase, Paper, Switch, Box, Divider, Badge } from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { filters, selectCurrentFilter, setPatientFilter } from "./patientsSlice";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import usePermissions from "../permissions/usePermissions";

interface ISearchBarProps {
    onChange: (newValue: string) => void;
    value: string;
}

const SearchBar: React.FC<ISearchBarProps> = ({ onChange, value }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const currentFilter = useAppSelector(selectCurrentFilter);
    const { hasPermissions } = usePermissions();
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchOnChange = (e: BaseSyntheticEvent) => {
        const val = e.target.value;
        onChange(val);
    };

    const handleFilterChange = (filterKey: string, checked: boolean) => {
        let updatedFilter;
        if (!checked) {
            updatedFilter = [...currentFilter, filterKey];
        } else {
            updatedFilter = currentFilter.filter((filter) => filter !== filterKey);
        }

        dispatch(setPatientFilter(updatedFilter));
    };

    const handleClearFilters = () => {
        dispatch(setPatientFilter([]));
    };

    return (
        <Paper sx={{ display: "flex", alignItems: "center" }}>
            <IconButton sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search patients"
                inputProps={{ "aria-label": "Search patients" }}
                onChange={handleSearchOnChange}
                value={value}
            />

            {hasPermissions(["QC"]) ? (
                <>
                    <IconButton onClick={handleClick} color="primary" sx={{ p: "10px" }} aria-label="directions">
                        <Badge color="secondary" variant="dot" invisible={currentFilter.length === 0}>
                            <FilterList />
                        </Badge>
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <Box style={{ padding: "0 8px" }}>
                            <DisplayText type="bodySmall" variant="semiBold" text="View patients" />
                            <Divider />
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <DisplayText type="bodyMedium" text="All" />
                                <Switch
                                    onChange={handleClearFilters}
                                    disabled={currentFilter.length === 0}
                                    checked={currentFilter.length === 0}
                                />
                            </Box>
                            <Divider />
                            {filters.map((filter) => (
                                <Fragment key={filter.key}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <DisplayText type="bodyMedium" text={filter.label} />
                                        <Switch
                                            onChange={(e) => handleFilterChange(filter.key, e.target.checked)}
                                            checked={!currentFilter.includes(filter.key)}
                                        />
                                    </Box>
                                </Fragment>
                            ))}
                        </Box>
                    </Menu>
                </>
            ) : null}
        </Paper>
    );
};

export default SearchBar;
