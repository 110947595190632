import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { loginUser, selectUserInfo } from "./loginSlice";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import theme from "../../../app/theme";
import { ReactComponent as VoxeleronLogo } from "../../../assets/VoxeleronLogo.svg";
import Copyright from "../../../components/CopyRight/Copyright";
import DisplayText from "../../../components/DisplayText/DisplayText";

const Div = styled("div")(({ theme }) => ({
    padding: "0 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
}));

const Form = styled("form")(({ theme }) => ({
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
}));

export function Login() {
    const userInfo = useAppSelector(selectUserInfo);
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (userInfo.isLoggedIn) {
            history.push("/patients");
        }
    }, []);

    function handleSubmit(event: any) {
        event.preventDefault();
        dispatch(loginUser({ email, password }));
    }

    return userInfo.isLoggedIn ? (
        <Redirect to="/patients" />
    ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Container
                sx={{ height: "100%", display: "flex", flexDirection: "column", gap: 20 }}
                component="main"
                maxWidth="xs"
            >
                <Box sx={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Box>
                        <VoxeleronLogo />
                    </Box>
                    <Div>
                        <Form noValidate onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <a href="https://voxeleron.com/inebula-terms-of-use/" target="_blank" rel="noreferrer">
                                <DisplayText
                                    style={{ textAlign: "right", textDecoration: "underline" }}
                                    type="bodyXSmall"
                                    text="Terms of Use"
                                />
                            </a>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={userInfo.status === "loading"}
                                color="primary"
                                sx={{
                                    margin: theme.spacing(3, 0, 2),
                                }}
                            >
                                {userInfo.status === "loading" ? "Signing In..." : "Sign in"}
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/password/recover" variant="body2">
                                        <DisplayText type="bodySmall" text="Forgot password?" />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Form>
                        {userInfo.errorMessage && <label style={{ color: "#FF0000" }}>{userInfo.errorMessage}</label>}
                    </Div>
                </Box>
                <Box sx={{ py: 20 }} id="researchOnly">
                    <DisplayText
                        style={{ textAlign: "center", marginBottom: 3 }}
                        type="bodyXSmall"
                        text="This Software is for research use only."
                    />
                    <Copyright />
                </Box>
            </Container>
        </Box>
    );
}

export default Login;
