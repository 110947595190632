import React, { useEffect } from "react";
import { Box } from "@mui/material";
import StudyCard from "./StudyCard/StudyCard";
import theme from "../../app/theme";
import { IMediaOctFile } from "./studiesSlice";
import { useGetMediaTokenMutation } from "./dataAccess";
import { regroupImages } from "../../utils/MediaUtils";
import DisplayText from "../../components/DisplayText/DisplayText";

interface StudyCardProps {
    visit: any;
    onCheckboxSelect: (id: string[], isChecked: boolean) => void;
    selectedStudies: Set<string>;
}

const VisitPanel: React.FC<StudyCardProps> = ({ visit, onCheckboxSelect, selectedStudies }) => {
    const [
        fetchToken,
        { data: token, error: tokenError, isLoading: tokenIsLoading, isUninitialized: tokenIsUninitialized },
    ] = useGetMediaTokenMutation();

    useEffect(() => {
        fetchToken();
    }, []);

    return (
        <Box
            sx={{
                color: theme.palette.text.secondary,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill,minmax(270px, 1fr))",
                gap: "10px",
            }}
        >
            {visit.mediaOctFile.length > 0 ? (
                regroupImages(visit.mediaOctFile).map((octFile: IMediaOctFile) => (
                    <StudyCard
                        visitMetadata={visit.imagingProcedure}
                        isLoading={tokenIsLoading || tokenIsUninitialized}
                        error={Boolean(tokenError)}
                        token={token}
                        selected={selectedStudies.has(octFile.uuid)}
                        actionsDisable={selectedStudies.size > 0}
                        onCheckboxSelect={onCheckboxSelect}
                        key={octFile.uuid}
                        octFile={octFile}
                    />
                ))
            ) : (
                <DisplayText type="bodyMedium" text="No Files" />
            )}
        </Box>
    );
};

export default VisitPanel;
