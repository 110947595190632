import React, { useMemo, useState } from "react";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useEditCRFMutation } from "../dataAccess";
import { Autocomplete, createFilterOptions, Input } from "@mui/material";
import DisplayText from "../../../components/DisplayText/DisplayText";
import { CrfField } from "../nebula.service";
import { moveToNextCell } from "../utils/moveToNextCell";
import { StyledTooltip } from "./StyledTooltip";
import { afterDecimal } from "../utils/afterDecimal";

interface IEditableOptionsProps extends GridRenderEditCellParams {
    options: any[];
    fieldDefinition: CrfField;
}

export const EditableOptions: React.FC<IEditableOptionsProps> = ({
    id,
    field,
    error,
    options,
    fieldDefinition,
    formattedValue = "",
}) => {
    const isOptionSelected = options.map((option) => option.label).includes(formattedValue);
    const [inputValue, setInputValue] = React.useState(`${isOptionSelected ? "" : formattedValue}`);
    const apiRef = useGridApiContext();
    const [currentHighlightedOption, setCurrentHighlightedOption] = useState<{ label: string; value: any } | null>(
        null
    );
    const { enqueueSnackbar } = useSnackbar();
    const [editCRF] = useEditCRFMutation();

    const handleUpdateValueOnServer = async (newValue: string) => {
        const rowValues = apiRef.current.getRow(id);

        const payload = {
            laterality: rowValues.laterality,
            visitId: rowValues.visitId,
            data: [
                {
                    fieldName: field,
                    fieldValue: newValue,
                },
            ],
        };

        try {
            const isValid = await apiRef.current.setEditCellValue({ id, field, value: newValue });

            if (!isValid) return false;

            await apiRef.current.stopCellEditMode({
                id,
                field,
            });

            // @ts-ignore
            await editCRF({ patientId: rowValues.patientId, crfUpdate: payload }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
            await apiRef.current.startCellEditMode({ id, field });
            await apiRef.current.setEditCellValue({ id, field, value: 987 });
        }
    };

    // const handleClose = (e: any) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     apiRef.current.stopCellEditMode({
    //         id,
    //         field,
    //         ignoreModifications: true,
    //     });
    // };

    // Apply new value, send update to the server and then move to next cell
    const handleKeyDown = async (event: any) => {
        switch (event.key) {
            case "Enter":
            case "Tab": {
                event.preventDefault();
                event.stopPropagation();

                const isValid = await apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: currentHighlightedOption!.value,
                });

                if (isValid) {
                    handleUpdateValueOnServer(currentHighlightedOption!.value);

                    // move to next cell, timeout is necessary to wait until cell update is finished
                    setTimeout(() => moveToNextCell({ id, field }, apiRef, event), 0);
                }

                break;
            }
            default:
        }
    };

    const filter = createFilterOptions<any>();

    const freeInputOption = useMemo(() => {
        return options.find((option) => option.type === "input");
    }, [options]);

    const selectableOptions = useMemo(() => {
        const fixedOptions = options.filter((option) => option.type !== "input");

        if (freeInputOption) {
            const precisionHint = fieldDefinition.precision ? `x.${"x".repeat(fieldDefinition.precision)}` : "";
            fixedOptions.unshift({
                label: `Type a value ${fieldDefinition.unit ? `in ${fieldDefinition.unit}` : ""} ${
                    precisionHint ? `(${precisionHint})` : ""
                }`,
                disabled: true,
            });
        }

        return fixedOptions;
    }, [options]);

    return (
        <StyledTooltip placement="top-start" open={!!error} title={error}>
            <Autocomplete
                sx={{ width: "100%" }}
                id="size-small-outlined"
                size="small"
                openOnFocus
                clearOnBlur
                autoHighlight
                inputValue={inputValue}
                onInputChange={(event, value, reason) => {
                    if (event && event.type === "blur") {
                        setInputValue("");
                    } else if (reason !== "reset") {
                        const isNumber = !isNaN(parseFloat(value));

                        if (!isNumber) {
                            return setInputValue(value);
                        }

                        if (
                            fieldDefinition.precision !== undefined &&
                            afterDecimal(parseFloat(value)) > fieldDefinition.precision
                        ) {
                            return;
                        }

                        setInputValue(value);
                    }
                }}
                options={selectableOptions}
                getOptionDisabled={(option) => !!option.disabled}
                onChange={async (event, newInputValue) => {
                    const isValid = await apiRef.current.setEditCellValue({ id, field, value: newInputValue.value });

                    if (isValid) {
                        handleUpdateValueOnServer(newInputValue.value);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (freeInputOption) {
                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.label);

                        if (inputValue !== "" && !isExisting) {
                            return [
                                {
                                    value: Number(inputValue),
                                    label: `${inputValue} ${fieldDefinition.unit ?? ""}`,
                                },
                            ];
                        }
                    }

                    return filtered;
                }}
                getOptionLabel={(option) => {
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        <DisplayText type="bodySmall" text={option.label} />
                    </li>
                )}
                onHighlightChange={(_, option) => {
                    setCurrentHighlightedOption(option);
                }}
                renderInput={({ InputLabelProps, ...params }) => {
                    params.inputProps.onKeyDown = handleKeyDown;

                    return (
                        <Input
                            {...params}
                            autoFocus
                            disableUnderline
                            sx={{ p: 6 }}
                            ref={params.InputProps.ref}
                            fullWidth
                        />
                    );
                }}
            />
        </StyledTooltip>
    );
};
