import React from "react";
import {
    CircularProgress,
    CircularProgressProps,
    Box,
    Card,
    Divider,
    Chip,
    LinearProgressProps,
    LinearProgress,
} from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import theme from "../../app/theme";
import Tooltip from "../../components/Tooltip/Tooltip";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number; title: string; subtitle: string; legend: string }
) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress thickness={0.6} variant="determinate" size={200} {...props} />
            <Tooltip content={<DisplayText style={{ whiteSpace: "pre-line" }} type="bodySmall" text={props.legend} />}>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <DisplayText text={props.title} type="h5" />
                    <DisplayText text={props.subtitle} type="bodyMedium" variant="regular" />
                </Box>
            </Tooltip>
        </Box>
    );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number; title: string }) {
    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 6 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <DisplayText type="bodyMedium" text={props.title} />
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, display: "flex", gap: 4, position: "relative", top: 10 }}>
                <DisplayText type="bodySmall" style={{ whiteSpace: "nowrap" }} variant="semiBold" text="68 /" />
                <DisplayText type="bodySmall" text="200" style={{ color: "#d9d9d9" }} />
            </Box>
        </Box>
    );
}

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
    return (
        <Box sx={{ padding: theme.spacing(20) }}>
            <DisplayText type="h3" text="Dashboard" />
            <Box sx={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
                <Card sx={{ p: 20, flex: "1 0 450px", maxWidth: "1000px" }}>
                    <DisplayText type="h5" text="Overview" />
                    <Divider />
                    <Box sx={{ display: "flex", gap: 12 }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 12,
                                mt: 12,
                                flex: 1,
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{ display: "flex", justifyContent: "space-around", flex: 1, alignItems: "center" }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 5,
                                    }}
                                >
                                    <DisplayText style={{ color: "#d9d9d9" }} type="bodyLarge" text="Last upload " />
                                    <DisplayText type="bodyLarge" text="Yesterday" variant="semiBold" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: 5,
                                    }}
                                >
                                    <DisplayText style={{ color: "#d9d9d9" }} type="bodyLarge" text="Days remaining " />
                                    <DisplayText type="bodyLarge" text="28" variant="semiBold" />
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgressWithLabel title="Reading rate" value={89} />
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <LinearProgressWithLabel title="Enrolled" value={89} />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 16 }}>
                            <CircularProgressWithLabel
                                legend={`Total: 200 cases
                            Completed: 68 cases
                            Remaining: 132 cases`}
                                value={34}
                                title="34%"
                                subtitle="cases completed"
                            />
                            <Box sx={{ display: "flex", gap: 5, mt: 12 }}>
                                <Chip
                                    sx={{ height: 18 }}
                                    size="small"
                                    color="success"
                                    label="11%"
                                    icon={<ArrowDropUpIcon />}
                                />
                                <DisplayText type="bodySmall" style={{ color: "#d9d9d9" }} text="since last week" />
                            </Box>
                        </Box>
                    </Box>
                </Card>
                <Card sx={{ p: 20 }}>
                    <DisplayText type="h5" text="Site 1" />
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 12, mt: 16 }}>
                        <CircularProgressWithLabel
                            legend={`Total: 100 cases
                            Completed: 45 cases
                            Remaining: 55 cases`}
                            value={45}
                            title="45%"
                            subtitle="cases uploaded"
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 8, mt: 12 }}>
                        <Box sx={{ display: "flex", gap: 5 }}>
                            <Chip
                                sx={{ height: 18 }}
                                size="small"
                                color="success"
                                label="12%"
                                icon={<ArrowDropUpIcon />}
                            />
                            <DisplayText type="bodySmall" style={{ color: "#d9d9d9" }} text="since last week" />
                        </Box>
                        <Box sx={{ display: "flex", gap: 5, alignItems: "baseline" }}>
                            <DisplayText style={{ color: "#d9d9d9" }} type="bodySmall" text="Last upload: " />
                            <DisplayText type="bodySmall" text="Yesterday" variant="semiBold" />
                        </Box>
                    </Box>
                </Card>
                <Card sx={{ p: 20 }}>
                    <DisplayText type="h5" text="Site 2" />
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 24, mt: 16 }}>
                        <CircularProgressWithLabel
                            legend={`Total: 100 cases
                            Completed: 23 cases
                            Remaining: 77 cases`}
                            value={23}
                            title="23%"
                            subtitle="cases uploaded"
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 8, mt: 12 }}>
                        <Box sx={{ display: "flex", gap: 5 }}>
                            <Chip
                                sx={{ height: 18 }}
                                size="small"
                                color="success"
                                label="10%"
                                icon={<ArrowDropUpIcon />}
                            />
                            <DisplayText type="bodySmall" style={{ color: "#d9d9d9" }} text="since last week" />
                        </Box>
                        <Box sx={{ display: "flex", gap: 5, alignItems: "baseline" }}>
                            <DisplayText style={{ color: "#d9d9d9" }} type="bodySmall" text="Last upload: " />
                            <DisplayText type="bodySmall" text="3 days ago" variant="semiBold" />
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default Dashboard;
