import { CircularProgress, Fade } from "@mui/material";
import EnFace from "./EnFace";
import Slices from "./Slices";
import { useAppDispatch } from "../../app/hooks";
import React, { memo, useEffect } from "react";
import { useGetEnFaceInfoQuery, useGetAnalysisQuery, useGetMediaInfoQuery } from "./dataAccess";
import { selectAnalysisFovea, IAnalysisFovea, IMediaInfo, IEnFaceInfo } from "./viewerSlice";
import { Box } from "@mui/system";

interface IcctMediaViewerProps {
    uuid: string;
    token: string;
    visitId: string;
}

const OctMediaViewer: React.FC<IcctMediaViewerProps> = ({ uuid, token, visitId }) => {
    const { data: enFaceInfo, error: enFaceInfoError, isLoading: enFaceInfoIsLoading } = useGetEnFaceInfoQuery(uuid);
    const { data: analysis } = useGetAnalysisQuery(uuid);
    const { data: mediaInfo, error: mediaInfoError, isLoading: mediaInfoIsLoading } = useGetMediaInfoQuery(uuid);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (analysis) {
            const fs = analysis.filter((i) => i.kind === "fovea").map((j) => j.data as IAnalysisFovea);
            if (fs.length > 0) {
                let aFovea = {
                    foveaX: Math.round(fs[0].foveaX),
                    foveaY: Math.round(fs[0].foveaY),
                };
                dispatch(selectAnalysisFovea({ aFovea }));
            }
        }
    }, [analysis]);

    if (enFaceInfoError || mediaInfoError) {
        return <Box>En error occurred. Please try again.</Box>;
    }

    if (mediaInfoIsLoading || enFaceInfoIsLoading) {
        return (
            <Fade
                in={!(mediaInfo && enFaceInfo)}
                style={{
                    transitionDelay: !(mediaInfo && enFaceInfo) ? "800ms" : "0ms",
                }}
                unmountOnExit
            >
                <CircularProgress />
            </Fade>
        );
    }

    return (
        <Box sx={{ display: "flex", aspectRatio: "5", width: "100%" }}>
            <Box sx={{ flex: 1, overflow: "hidden" }} id={`${visitId}-enface-container`}>
                <EnFace
                    key={`EnFace-${visitId}`}
                    visitId={visitId}
                    token={token}
                    octFileId={uuid}
                    mediaInfo={mediaInfo as IMediaInfo}
                    enfaceInfo={enFaceInfo as IEnFaceInfo}
                />
            </Box>
            <Box sx={{ flex: 3, overflow: "hidden" }} id={`${visitId}-slices-container`}>
                <Slices
                    key={`Slices-${visitId}`}
                    visitId={visitId}
                    token={token}
                    octFileId={uuid}
                    mediaInfo={mediaInfo as IMediaInfo}
                />
            </Box>
        </Box>
    );
};

export default memo(OctMediaViewer);
