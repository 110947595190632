import React, { useEffect, useRef, useState, memo } from "react";
import Swipe from "react-easy-swipe";
import klass from "./cssClasses";
import "./styles/main.scss";
import "./carousel.scss";
import { figureSource, figureComponent } from "../../utils/MediaUtils";
import { IMediaOctFile, IStudy } from "../studies/studiesSlice";
import { useGetMediaTokenMutation } from "../studies/dataAccess";
import { Description as DescriptionIcon } from "@mui/icons-material";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DisplayText from "../../components/DisplayText/DisplayText";

interface CarouselProps {
    visit: IStudy;
    selectedStudyId: string;
    onClick: (studyId: string) => void;
}

const Carousel: React.FC<CarouselProps> = ({ visit, selectedStudyId, onClick }) => {
    const studies = visit.mediaOctFile;

    const [fetchToken, { data: token, isLoading: tokenIsLoading, isUninitialized: tokenIsUninitialized }] =
        useGetMediaTokenMutation();

    useEffect(() => {
        fetchToken();
    }, [studies]);

    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [firstItem, setFirstItem] = useState<number>(0);
    const [itemSize, setItemSize] = useState<number>(0);
    const [visibleItems, setVisibleItems] = useState<number>(0);
    const [lastPosition, setLastPosition] = useState<number>(0);
    const [swiping, setSwiping] = useState<boolean>(false);

    const itemsWrapperRef = useRef<HTMLDivElement>();
    const itemsListRef = useRef<HTMLUListElement>();
    const thumbsRef = useRef<HTMLLIElement[]>([]);

    const outerWidth = (el: HTMLLIElement) => {
        let width = el.offsetWidth;
        const style = getComputedStyle(el);
        width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        return width;
    };

    useEffect(() => {
        const total = studies ? studies.length : 0;
        const wrapperSize = itemsWrapperRef.current ? itemsWrapperRef.current.clientWidth : 0;
        const showArrows = visibleItems < total;
        const itemSelect = studies ? studies.findIndex((octFile) => octFile.uuid === selectedStudyId) : 0;
        setSelectedItem(itemSelect);
        setItemSize(outerWidth(thumbsRef.current[0]));
        setLastPosition(showArrows ? total - visibleItems : 0);
        if (itemSize) setVisibleItems(Math.floor(wrapperSize / itemSize));
    }, [itemSize, selectedItem, studies, visibleItems, visit, selectedStudyId]);

    useEffect(() => {
        let first = selectedItem;

        if (selectedItem >= lastPosition) {
            first = lastPosition;
        }

        if (selectedItem < firstItem + visibleItems) {
            first = firstItem;
        }

        setFirstItem(first);
    }, [firstItem, lastPosition, selectedItem, visibleItems]);

    const setItemsWrapperRef = (node: HTMLDivElement) => {
        itemsWrapperRef.current = node;
    };

    const setItemsListRef = (node: HTMLUListElement) => {
        itemsListRef.current = node;
    };

    const slideRight = (positions?: number) => {
        moveTo(firstItem - (typeof positions === "number" ? positions : 1));
    };

    const slideLeft = (positions?: number) => {
        moveTo(firstItem + (typeof positions === "number" ? positions : 1));
    };

    const moveTo = (position: number) => {
        // position can't be lower than 0
        position = position < 0 ? 0 : position;
        // position can't be higher than last postion
        position = position >= lastPosition ? lastPosition : position;
        setFirstItem(position);
    };

    const setThumbsRef = (node: HTMLLIElement) => {
        thumbsRef.current.push(node);
    };

    const handleClickItem = (oct: IMediaOctFile) => {
        onClick(oct.uuid);
    };

    const onSwipeMove = (delta: { x: number; y: number }) => {
        let deltaX = delta.x;
        if (!itemSize || !itemsWrapperRef.current || !visibleItems) {
            return false;
        }
        const leftBoundary = 0;
        const childrenLength = studies?.length || 0;

        const currentPosition = -(firstItem * 100) / visibleItems;
        const lastLeftItem = Math.max(childrenLength - visibleItems, 0);
        const lastLeftBoundary = (-lastLeftItem * 100) / visibleItems;

        // prevent user from swiping left out of boundaries
        if (currentPosition === leftBoundary && deltaX > 0) {
            deltaX = 0;
        }

        // prevent user from swiping right out of boundaries
        if (currentPosition === lastLeftBoundary && deltaX < 0) {
            deltaX = 0;
        }

        const wrapperSize = itemsWrapperRef.current?.clientWidth || 0;
        const position = currentPosition + 100 / (wrapperSize / deltaX);
        // if 3d isn't available we will use left to move
        if (itemsListRef.current) {
            ["WebkitTransform", "MozTransform", "MsTransform", "OTransform", "transform", "msTransform"].forEach(
                (prop) => {
                    itemsListRef.current!.style[prop as any] = CSSTranslate(position, "%", "horizontal");
                }
            );
        }

        return true;
    };

    const onSwipeStart = () => {
        setSwiping(true);
    };

    const onSwipeEnd = () => {
        setSwiping(false);
    };

    const CSSTranslate = (position: number, metric: "px" | "%", axis: "horizontal" | "vertical") => {
        const positionPercent = position === 0 ? position : position + metric;
        const positionCss = axis === "horizontal" ? [positionPercent, 0, 0] : [0, positionPercent, 0];
        const transitionProp = "translate3d";
        const translatedPosition = "(" + positionCss.join(",") + ")";
        return transitionProp + translatedPosition;
    };

    const hasPrev = firstItem > 0;
    const hasNext = firstItem < lastPosition;
    const currentPosition = -firstItem * (itemSize || 0);
    const transformProp = CSSTranslate(currentPosition, "px", "horizontal");
    const transitionTime = 350 + "ms";
    const itemListStyles = {
        WebkitTransform: transformProp,
        MozTransform: transformProp,
        MsTransform: transformProp,
        OTransform: transformProp,
        transform: transformProp,
        msTransform: transformProp,
        WebkitTransitionDuration: transitionTime,
        MozTransitionDuration: transitionTime,
        MsTransitionDuration: transitionTime,
        OTransitionDuration: transitionTime,
        transitionDuration: transitionTime,
        msTransitionDuration: transitionTime,
    };

    const StudiesThumbnails = studies.map((oct: IMediaOctFile, index: number) => {
        const itemClass = klass.ITEM(false, index === selectedItem);
        const size = "60px";

        const thumbProps = {
            "key": index,
            "ref": (el: HTMLLIElement) => setThumbsRef(el),
            "className": itemClass,
            "aria-label": `${oct.FileName} ${index + 1}`,
            "style": { width: size, height: size, cursor: "pointer" },
        };

        const renderThumbnail = (componentType: string) => {
            switch (componentType) {
                case "img":
                    return <img alt={oct.FileName} src={src as string} {...elProps} />;
                case "video":
                    return (
                        <>
                            {" "}
                            <Avatar
                                aria-label="label"
                                sx={{
                                    backgroundColor: "grey",
                                    position: "absolute",
                                    marginTop: "4px",
                                    marginLeft: "4px",
                                    width: "20px",
                                    height: "20px",
                                }}
                                variant="rounded"
                            >
                                <Typography sx={{ fontWeight: 1000, fontSize: "10px" }}>{oct.Laterality}</Typography>
                            </Avatar>
                            <video src={src as string} {...elProps} />{" "}
                        </>
                    );
                case "iframe":
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <DescriptionIcon sx={{ width: "30px", height: "30px" }} />
                            <Typography sx={{ fontSize: "10px" }}>PDF</Typography>
                        </Box>
                    );
                case "attachment":
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <AttachFileIcon />
                            <DisplayText type="bodyXSmall" text={oct.FileName.split(".").at(-1) || ""} />
                        </Box>
                    );
            }
        };

        if (tokenIsLoading || tokenIsUninitialized) {
            return (
                // @ts-ignore
                <li {...thumbProps} tabIndex={0}>
                    <Skeleton variant="rectangular" height={60} width={60} />
                </li>
            );
        }

        const src = figureSource(oct, token as string);
        const component = figureComponent(oct);

        const elProps = {
            style: { width: size, height: size },
        };
        const divProps = {
            style: { textAlign: "center" as "center" },
        };

        const handleClick = () => handleClickItem(oct);

        if (!component || !src) return null;

        return (
            // @ts-ignore
            <li {...thumbProps} onClick={handleClick} tabIndex={0}>
                {renderThumbnail(component)}
            </li>
        );
    });

    return (
        <div className={klass.CAROUSEL(false)}>
            {/* @ts-ignore */}
            <div className={klass.WRAPPER(false)} ref={setItemsWrapperRef}>
                <button type="button" className={klass.ARROW_PREV(!hasPrev)} onClick={() => slideRight()} />
                <Swipe
                    tagName="ul"
                    className={klass.SLIDER(false, swiping)}
                    onSwipeLeft={slideLeft}
                    onSwipeRight={slideRight}
                    onSwipeMove={onSwipeMove}
                    onSwipeStart={onSwipeStart}
                    onSwipeEnd={onSwipeEnd}
                    style={itemListStyles}
                    innerRef={setItemsListRef}
                    allowMouseEvents={true}
                >
                    {StudiesThumbnails}
                </Swipe>
                <button type="button" className={klass.ARROW_NEXT(!hasNext)} onClick={() => slideLeft()} />
            </div>
        </div>
    );
};

export default memo(Carousel);
