import { isUserLoggedIn } from "../auth/login/loginSlice";
import { useGetUserDataQuery } from "../auth/dataAccess";
import { useAppSelector } from "../../app/hooks";

export type Permission =
    | "Upload"
    | "Review"
    | "Launch Orion"
    | "Download"
    | "Change Status"
    | "Edit"
    | "Delete"
    | "QC"
    | "Report"
    | "Master Admin";

const usePermissions = () => {
    const loggedIn = useAppSelector(isUserLoggedIn);
    const { data: userData } = useGetUserDataQuery(undefined, { skip: !loggedIn });

    // The permissions come in "Permission1; Permission2; Permission3;" format
    const parsePerMissions = (permissionsString: string) => {
        // Split it by ; and then trim it
        const permissionsList = permissionsString
            // split permissions list
            .split(";")
            // remove empty strings if exist
            .filter((permission) => permission !== "")
            // trim all permission string
            .map((permission) => permission.trim());

        return permissionsList;
    };

    const hasPermissions = (requestedPermissions: Permission[]) => {
        if (!userData) return false;

        const userPermissionArray = parsePerMissions(userData.adminFeatures);

        const userHasAllRequestedPermissions = requestedPermissions.every((permission: Permission) =>
            userPermissionArray.includes(permission)
        );

        return userHasAllRequestedPermissions;
    };

    return { hasPermissions };
};

export default usePermissions;
