import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentPatient } from "../patients/patientsSlice";
import { Box } from "@mui/system";
import { Switch } from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { AssignmentInd as AssignmentIndIcon } from "@mui/icons-material";
import { useEditPatientMutation } from "../patients/dataAccess";
import { useSnackbar } from "notistack";
import usePermissions from "../permissions/usePermissions";

interface IPatientInformationProps {
    label: string;
    value: string;
}
const PatientInformation: React.FC<IPatientInformationProps> = ({ label, value }) => {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 4 }}>
                <DisplayText type="bodyMedium" variant="regular" text={`${label}:`} />
            </Box>
            <DisplayText type="bodyMedium" variant="semiBold" text={value} />
        </Box>
    );
};

function PatientInfo() {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const [editPatient] = useEditPatientMutation();
    const { hasPermissions } = usePermissions();
    const { enqueueSnackbar } = useSnackbar();

    const handleToggleQC = async () => {
        try {
            await editPatient({
                isQc: !currentPatient.isQc,
                uuid: currentPatient.uuid,
            }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                    xs: "column",
                    md: "row",
                },
            }}
        >
            <Box>
                <Box sx={{ display: "flex" }}>
                    <AssignmentIndIcon fontSize="large" />
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <DisplayText type="bodyXSmall" variant="regular" text={"Patient ID"} />
                        <DisplayText type="bodyMedium" variant="semiBold" text={currentPatient.patientId} />
                    </Box>
                </Box>
                <DisplayText style={{ margin: 0 }} type="h4" text={currentPatient.patientName.toUpperCase()} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <PatientInformation label="Group/Site" value={currentPatient.group} />
                <PatientInformation label="Date of birth" value={currentPatient.dateOfBirth} />
                <PatientInformation label="Gender" value={currentPatient.gender} />
                {hasPermissions(["QC"]) ? (
                    <Box style={{ display: "flex", alignItems: "center", height: 28 }}>
                        <DisplayText type="bodyMedium" variant="regular" text="QC only:" />
                        <Switch color="primary" onChange={handleToggleQC} checked={currentPatient.isQc} />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
}

export default PatientInfo;
