import React, { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUploadState, UploadStep, resetUploadSession, selectFileEntries } from "./uploadSlice";
import { useGetUploadConfigQuery } from "./dataAccess";
import {
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    Container,
    FormControl,
    InputLabel,
    // CircularProgress,
} from "@mui/material";
import theme from "../../app/theme";
import FileUpload from "./components/FileUpload/FileUpload";
import { Box } from "@mui/system";
import FileViewer from "./components/FileViewer/FileViewer";
import { useConfirmationModal } from "../../components/ConfirmationDialog";
import { useGetUserDataQuery } from "../auth/dataAccess";

const Upload = () => {
    const uploadStep = useAppSelector(selectUploadState);
    const { data: userData } = useGetUserDataQuery();

    const dispatch = useAppDispatch();
    // const [selectedTrialId, setSelectedTrialId] = useState<string>("");
    const [selectedVisitId, setSelectedVisitId] = useState<string>("");
    const fileEntries = useAppSelector(selectFileEntries);

    const { openConfirmationModal } = useConfirmationModal({
        title: "You upload will be cancelled",
        content: "Changing the visit will cancel this upload.",
        acceptLabel: "Change Visit",
        cancelLabel: "Go back",
    });

    // const { data: trials } = useGetTrialsQuery();
    const { data: uploadConfig, isFetching: uploadConfigLoading } = useGetUploadConfigQuery();

    // As default we are going to use mimeTypes to validate file types
    // Since we are going to support some files without a standard mimeType,
    // when receiving one of those, we will use the file extension (file.{extension}) in order to infer the file type

    // const { data: validFiles, isLoading: validFileExtensionsIsLoading } = useGetValidFileExtensionsQuery();
    // const validFileExtensions: string[] = useMemo(() => {
    //     if (!validFiles) return [];
    //
    //     return Object.keys(validFiles).map((extension) => extension);
    // }, [validFiles]);
    //
    // const validFileMimeTypes: string[] = useMemo(() => {
    //     if (!validFiles) return [];
    //
    //     return Object.values(validFiles).map((mimeType) => mimeType.label);
    // }, [validFiles]);

    const handleVisitIdentifierChange = (e: SelectChangeEvent) => {
        const selectVisit = () => setSelectedVisitId(e.target.value);

        if (fileEntries.length > 0)
            return openConfirmationModal(() => {
                dispatch(resetUploadSession());
                selectVisit();
            });

        selectVisit();
    };

    const renderUploadStep = () => {
        if (uploadStep === UploadStep.FILE_UPLOAD) {
            return (
                <div>
                    <FileUpload
                        validFileExtensions={uploadConfig?.validExtensions?.join(",")}
                        folderConventionRegEx={uploadConfig?.folderConventionRegEx}
                        uploadSizeLimitMb={uploadConfig?.uploadSizeLimitMb}
                        validFolderNames={uploadConfig?.validExampleFolders}
                        selectedVisitId={selectedVisitId}
                        selectedGroupId={userData!.activeOrgUuid}
                        disabled={!selectedVisitId}
                    />
                </div>
            );
        }

        return <FileViewer />;
    };

    // // @ts-ignore
    // const renderTrialItem = (trial: ITrail) => [
    //     <MenuItem
    //         sx={{
    //             ...(trial.current.depth > 0 && {
    //                 marginLeft: trial.current.depth * 32,
    //                 borderLeft: "1px solid",
    //             }),
    //         }}
    //         value={trial.current.uuid}
    //     >
    //         {trial.current.label}
    //     </MenuItem>,
    //     trial.children.map(renderTrialItem),
    // ];

    const renderVisitIdentifiersItems = (uuid: string, label: string) => (
        <MenuItem key={uuid} value={uuid}>
            {label}
        </MenuItem>
    );

    const visitItems = useMemo(() => {
        if (!uploadConfig) return;

        return Object.entries(uploadConfig.visitIdentifiers).map(([uuid, label]) =>
            renderVisitIdentifiersItems(uuid, label)
        );
    }, [uploadConfig]);

    // const trialItems = useMemo(() => {
    //     if (!trials) return;
    //
    //     return trials.map(renderTrialItem);
    // }, [trials]);

    // if (validFileExtensionsIsLoading) {
    //     return (
    //         <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
    //             <CircularProgress />
    //         </Box>
    //     );
    // }

    return (
        <Container sx={{ padding: theme.spacing(20) }} component="main" maxWidth="lg">
            <Box sx={{ p: 20 }}>
                <Typography variant="h5" gutterBottom component="h5">
                    File Upload
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="p">
                    Files will be assigned to visit:
                </Typography>
                <Box sx={{ display: "flex", gap: 10 }}>
                    {/*<FormControl sx={{ m: 1, minWidth: 250 }}>*/}
                    {/*    <InputLabel>{!trials ? "Loading..." : "Select trial"}</InputLabel>*/}
                    {/*    <Select*/}
                    {/*        autoWidth={false}*/}
                    {/*        label="Loading..."*/}
                    {/*        value={selectedTrialId}*/}
                    {/*        disabled={trialsLoading || uploadStep === UploadStep.FILE_VIEWER_READY_FOR_COMMIT}*/}
                    {/*        onChange={handleProjectChange}*/}
                    {/*    >*/}
                    {/*        {trialItems}*/}
                    {/*    </Select>*/}
                    {/*</FormControl>*/}

                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                        <InputLabel>{uploadConfigLoading ? "Loading..." : "Select visit"}</InputLabel>
                        <Select
                            autoWidth={false}
                            label="Select visit"
                            id="selectVisitDropdown"
                            data-test-id="upload:visitSelector"
                            value={selectedVisitId}
                            disabled={uploadConfigLoading}
                            onChange={handleVisitIdentifierChange}
                        >
                            {visitItems}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ mt: 16 }}>{renderUploadStep()}</Box>
            </Box>
        </Container>
    );
};

export default Upload;
