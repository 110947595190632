import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    Checkbox,
    CircularProgress,
    Button,
    Paper,
    Typography,
    Divider,
    Tabs,
    Tab,
} from "@mui/material";
import VisitPanel from "./VisitPanel";
import { ICRFData, IStudy } from "./studiesSlice";
import { useGetPatientVisitsQuery, useAttachFileMutation } from "./dataAccess";
import { selectCurrentPatient } from "../patients/patientsSlice";
import PatientInfo from "./PatientInfo";
import { ArrowForwardIosSharp, ExpandMore, Upload as UploadIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import { styled } from "@mui/styles";
import { Layers as LayersIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import DrawerHeader from "./DrawerHeader";
import DisplayText from "../../components/DisplayText/DisplayText";
import queryString from "query-string";
import { getQueryParamFromStudyId } from "../media/utils";
import TabPanel from "../../components/Tab/TabPanel";
import { common, grey } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import CRFTab from "./CRFTab";
import StudiesFilters from "./StudiesFilters";
import { useGetPatientCrfQuery } from "../crf/dataAccess";

const CustomAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={1} square {...props} />)(
    () => ({
        backgroundColor: "#1f2735",
    })
);

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />} {...props} />
))(() => ({
    "flexDirection": "row-reverse",
    "& .MuiAccordionSummary-content": {
        margin: "0px",
    },
}));

const HiddenInput = styled("input")({
    display: "none",
});

interface IVisitAccordionProps {
    visit: IStudy;
    title: string;
    isDefaultOpen?: boolean;
    onSelect: (ids: string[], checked: boolean) => void;
    selectedStudies: Set<string>;
}

const VisitAccordion: React.FC<IVisitAccordionProps> = ({
    visit,
    title,
    isDefaultOpen = false,
    onSelect,
    selectedStudies,
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(isDefaultOpen);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [attachFile] = useAttachFileMutation();

    const handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
        setExpanded(expanded);
    };

    const handleSelectAllStudies = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const isChecked = event.target.checked;

        const studiesIds = visit.mediaOctFile.map((octFile) => octFile.uuid);

        onSelect(studiesIds, isChecked);
    };

    const selectionState = useMemo(() => {
        const studiesIds = visit.mediaOctFile.map((octFile) => octFile.uuid);

        const selectedStudyInVisit = Array.from(selectedStudies).filter((selectedStudyId) =>
            studiesIds.includes(selectedStudyId)
        );

        if (selectedStudyInVisit.length === 0) return "NONE";

        if (selectedStudyInVisit.length === studiesIds.length) return "ALL";

        return "SOME";
    }, [selectedStudies]);

    const handleAttachFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        enqueueSnackbar("Attaching file...", {
            variant: "info",
            persist: true,
        });
        // @ts-ignore
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("key", visit.imagingProcedure.uuid);
        formData.append("fileData", file);

        await attachFile(formData);

        closeSnackbar();
    };

    return (
        <Box>
            <CustomAccordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded}
                onChange={handleAccordionChange}
                sx={{ margin: "0px", backgroundColor: "#1f2735" }}
            >
                <CustomAccordionSummary
                    sx={{ backgroundColor: "#1f2735" }}
                    expandIcon={<ExpandMore />}
                    aria-controls={visit.imagingProcedure.uuid}
                    id={visit.imagingProcedure.uuid}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                        <Typography sx={{ padding: "10px" }}>{title}</Typography>
                        <Box>
                            {/*<label>*/}
                            {/*    <HiddenInput*/}
                            {/*        onChange={handleAttachFile}*/}
                            {/*        type="file"*/}
                            {/*        accept="image/*,application/*,text/*,video/mp4"*/}
                            {/*    />*/}
                            {/*    <Button*/}
                            {/*        size="small"*/}
                            {/*        sx={{*/}
                            {/*            "color": common.white,*/}
                            {/*            "align-items": "center",*/}
                            {/*            "border": `1px solid ${common.white}`,*/}
                            {/*            "&:hover": {*/}
                            {/*                borderColor: grey[300],*/}
                            {/*            },*/}
                            {/*        }}*/}
                            {/*        variant="outlined"*/}
                            {/*        component="span"*/}
                            {/*        startIcon={<UploadIcon />}*/}
                            {/*    >*/}
                            {/*        attach file*/}
                            {/*    </Button>*/}
                            {/*</label>*/}

                            <Checkbox
                                indeterminate={selectionState === "SOME"}
                                checked={selectionState === "ALL"}
                                /* @ts-ignore */
                                onClick={handleSelectAllStudies}
                            />
                        </Box>
                    </Box>
                </CustomAccordionSummary>
                <AccordionDetails sx={{ p: 16, backgroundColor: "#1f2735" }}>
                    <VisitPanel selectedStudies={selectedStudies} visit={visit} onCheckboxSelect={onSelect} />
                </AccordionDetails>
            </CustomAccordion>
        </Box>
    );
};

const Studies = () => {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const history = useHistory();

    const {
        data,
        error: studyError,
        isLoading: studyLoading,
        isFetching,
        refetch: refetchVisits,
        // @ts-ignore
    } = useGetPatientVisitsQuery(currentPatient.uuid, { refetchOnMountOrArgChange: true });
    const { data: patientCrf, error: crfError, isLoading: crfLoading } = useGetPatientCrfQuery(currentPatient.uuid);

    const { visits } = useMemo(() => {
        if (!data) {
            return { crfs: [], visits: [] };
        }

        return { visits: data.studies, crfs: data.crfs };
    }, [data]);

    const { crfVisits } = useMemo(() => {
        if (!patientCrf) {
            return { crfVisits: [] };
        }

        return { crfVisits: patientCrf.visits };
    }, [patientCrf]);

    const [selectedStudies, setSelectedStudies] = useState<Set<string>>(new Set());

    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSelect = (ids: string[], isChecked: boolean) => {
        const newSelectedStudies = new Set(selectedStudies);

        if (isChecked) {
            ids.forEach(newSelectedStudies.add, newSelectedStudies);
        } else {
            ids.forEach(newSelectedStudies.delete, newSelectedStudies);
        }

        return setSelectedStudies(newSelectedStudies);
    };

    const selectAllAvailable = useMemo(() => {
        return selectedStudies.size > 0;
    }, [selectedStudies]);

    const handleViewAll = () => {
        const selectedStudiesIds = Array.from(selectedStudies);

        if (selectedStudiesIds.length === 0) return;

        // Adding random id before id as query params to be able to differentiate when having to media cards
        // with the same id. This is important for example when closing a card
        const studiesIdsAsQueryParams = Array.from(selectedStudies).map(getQueryParamFromStudyId);

        history.push({
            pathname: `/visits/${currentPatient!.uuid}`,
            search: queryString.stringify({ st: studiesIdsAsQueryParams }),
        });
    };

    const showCRFTab = useMemo(() => {
        if (!data) return false;

        return data.crfs.crfs.length > 0;
    }, [data]);

    // @ts-ignore
    const crfNotAllowed = useMemo(() => crfError && crfError.status === 401, [crfError]);
    // @ts-ignore
    const crfErrorHappened = useMemo(() => crfError && crfError.status !== 401, [crfError]);

    return (
        <Paper sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <DrawerHeader />
            <Divider />
            <Box sx={{ px: 16, py: 24, overflow: "hidden", flex: 1, display: "flex", flexDirection: "column" }}>
                <PatientInfo />
                {!isFetching && studyError ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <DisplayText type="bodyMedium" text="An error occurred" />
                            <Button size="small" variant="contained" onClick={refetchVisits}>
                                Retry
                            </Button>
                        </Box>
                    </Box>
                ) : studyLoading || isFetching || crfLoading ? (
                    <Box
                        sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Studies" />
                                {showCRFTab ? <Tab label="CRF" /> : null}
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0} style={{ overflow: "auto" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 16,
                                    flexDirection: {
                                        xs: "column",
                                        md: "row",
                                    },
                                    gap: "6px",
                                }}
                            >
                                <StudiesFilters
                                    visits={visits}
                                    selectedStudies={selectedStudies}
                                    onSelect={setSelectedStudies}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flex: {
                                            xs: 0,
                                            md: "0 0 280px",
                                        },
                                        alignItems: "flex-start",
                                        justifyContent: {
                                            xs: "space-between",
                                            md: "flex-start",
                                        },
                                    }}
                                >
                                    {selectedStudies.size > 0 ? (
                                        <>
                                            <Box
                                                sx={{
                                                    mr: 8,
                                                    display: "flex",
                                                    gap: 4,
                                                    position: "relative",
                                                    top: "8px",
                                                }}
                                            >
                                                <DisplayText
                                                    type="bodySmall"
                                                    variant="semiBold"
                                                    text={selectedStudies.size.toString()}
                                                />
                                                <DisplayText
                                                    type="bodySmall"
                                                    variant="regular"
                                                    text={"Studies selected"}
                                                />
                                            </Box>
                                            <Button
                                                disabled={!selectAllAvailable}
                                                variant="contained"
                                                size="small"
                                                startIcon={<LayersIcon />}
                                                onClick={handleViewAll}
                                            >
                                                View Selected
                                            </Button>
                                        </>
                                    ) : null}
                                </Box>
                            </Box>
                            <Box sx={{ overflow: "auto", mt: 12, flex: 1 }}>
                                {visits?.map((visit, index) => (
                                    <Box sx={{ mt: 16 }} key={visit.imagingProcedure.uuid}>
                                        <VisitAccordion
                                            onSelect={handleSelect}
                                            key={visit.imagingProcedure.uuid}
                                            isDefaultOpen={index === 0}
                                            visit={visit}
                                            title={`${visit.imagingProcedure.visit || "Visit"} (${new Date(
                                                visit.imagingProcedure.orderDate
                                            ).toLocaleDateString()}) | ${visit.imagingProcedure.trialName}`}
                                            selectedStudies={selectedStudies}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </TabPanel>
                        {crfNotAllowed ? null : (
                            <TabPanel value={tabValue} index={1} style={{ overflow: "auto", height: "100%" }}>
                                {crfErrorHappened ? (
                                    <Box
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Something went wrong
                                    </Box>
                                ) : (
                                    <CRFTab visits={crfVisits} />
                                )}
                            </TabPanel>
                        )}
                    </>
                )}
            </Box>
        </Paper>
    );
};

export default Studies;
