import OctMediaViewer from "./OctMediaViewer";
import ImageMediaViewer from "./ImageMediaViewer";
import PdfMediaViewer from "./PdfMediaViewer";
import { figureFlavor } from "../../utils/MediaUtils";
import { selectStudyById } from "./viewerSlice";
import React, { memo, useCallback, useEffect } from "react";
import { IStudy } from "../studies/studiesSlice";
import { useGetPatientVisitsQuery, useGetMediaTokenMutation } from "../studies/dataAccess";
import { CardMedia, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import TextFromFile from "../../components/TextFromFile/TextFromFile";
import { Box } from "@mui/system";
import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import CSVToTable from "../../components/CSVToTable/CSVToTable";

interface IMediaViewerFileProps {
    mediaViewerId: string;
    studyId: string;
}
const MediaViewerFile: React.FC<IMediaViewerFileProps> = ({ mediaViewerId, studyId }) => {
    const { patientId } = useParams<{ patientId: string }>();

    const [fetchToken, { data: token, isLoading: tokenIsLoading, isUninitialized: tokenIsUninitialized }] =
        useGetMediaTokenMutation();

    const { data } = useGetPatientVisitsQuery(patientId);

    const study = selectStudyById(data!.studies as IStudy[], studyId as string);

    useEffect(() => {
        fetchToken();
    }, []);

    const Component = useCallback(() => {
        if (!study) return null;
        switch (figureFlavor(study)) {
            case "mp4":
                return (
                    <CardMedia
                        height="100%"
                        width="auto"
                        sx={{ height: "100%", width: "auto" }}
                        component="video"
                        src={`${API_MEDIA_BASE_PATH}/t/${token}/media/stream/mp4/${studyId}`}
                        controls={true}
                    />
                );
            case "oct-mp4":
            case "oct-photo":
                return <OctMediaViewer token={token as string} uuid={studyId as string} visitId={mediaViewerId} />;
            case "csv":
                return <CSVToTable url={`${API_MEDIA_BASE_PATH}/media/stream/csv/${studyId}`} />;
            case "photo":
                return <ImageMediaViewer token={token as string} uuid={studyId as string} />;
            case "pdf":
                return <PdfMediaViewer token={token as string} uuid={studyId as string} />;
            case "text":
                return (
                    <Box sx={{ width: "100%", p: 16 }}>
                        <TextFromFile studyId={studyId} />
                    </Box>
                );
            default:
                return <ImageMediaViewer token={token as string} uuid={studyId as string} />;
        }
    }, [study, token]);

    if (tokenIsLoading || tokenIsUninitialized) {
        return <CircularProgress />;
    }

    return <Component />;
};

export default memo(MediaViewerFile);
