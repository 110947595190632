// @ts-ignore
import throttle from "lodash.throttle";

export class Timer {
    private readonly timeout: number;
    private readonly onTimeout: () => void;
    private events: string[];
    private readonly eventHandler: () => void;
    private interval: any;
    private timeoutTracker: any;

    constructor({ timeout, onTimeout }: { timeout: number; onTimeout: () => void }) {
        this.timeout = timeout;
        this.onTimeout = onTimeout;
        this.events = ["mousemove", "mouseup", "mousedown", "click", "scroll", "keyup", "keydown", "keypress"];

        this.eventHandler = throttle(this.updateExpiredTime.bind(this), 3000);
        this.tracker();
    }

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem("_expiredTime") || "0", 10);
            if (expiredTime < Date.now()) {
                if (this.onTimeout) {
                    this.onTimeout();
                    this.cleanUp();
                }
            }
        }, 1000);
    }

    updateExpiredTime() {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }

        this.timeoutTracker = setTimeout(() => {
            const time = Date.now() + this.timeout * 60000;

            localStorage.setItem("_expiredTime", time.toString());
        }, 300);
    }

    tracker() {
        this.events.forEach((event) => {
            window.addEventListener(event, this.eventHandler);
        });
    }

    cleanUp() {
        localStorage.removeItem("_expiredTime");

        clearInterval(this.interval);

        this.events.forEach((event) => {
            window.removeEventListener(event, this.eventHandler);
        });
    }
}
