import React, { useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

interface INoteDialogProps {
    onClose: () => void;
    onAccept: (newNote: string) => Promise<unknown>;
    open: boolean;
    initialNotes?: string;
}

const NoteDialog: React.FC<INoteDialogProps> = ({ onAccept, onClose, open, initialNotes = "" }) => {
    const [notes, setNotes] = useState(initialNotes);
    const [isLoading, setIsLoading] = useState(false);

    const isDirty = useMemo(() => {
        return notes !== initialNotes;
    }, [notes, initialNotes]);

    const handleSaveNote = async () => {
        setIsLoading(true);
        try {
            await onAccept(notes);
            onClose();
        } finally {
            setIsLoading(false);
        }
    };

    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => setNotes(e.target.value);

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
            <DialogTitle>Notes</DialogTitle>
            <DialogContent>
                <TextField autoFocus fullWidth onChange={handleNotesChange} multiline maxRows={4} value={notes} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button disabled={!isDirty || isLoading} onClick={handleSaveNote} autoFocus>
                    {isLoading ? "Saving..." : "Save changes"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NoteDialog;
