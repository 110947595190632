import { nebulaClient } from "../../lib/nebulaClient";
import { MediaService } from "./nebula.service";
import { ILoginInfo } from "../auth/login/loginSlice";

export interface EnFaceInfoDTO {
    uuid: string;
    EnFaceWidth: number;
    EnFaceHeight: number;
    EnFaceWidthSizeInMicrons: number;
    EnFaceHeightSizeInMicrons: number;
}

export interface AnalysisDTO {
    kind: string;
    date: string;
    user: string;
    data: any;
}

export interface MediaInfoDTO {
    uuid: string;
    createdOn: string;
    modifiedOn: string;
    endedOnOpt: string;
    isActive: boolean;
    label: string;
    metaOpt: any;
    schema: string;
    mimeType: string;
    mediaLocationUri: string;
    mediaStatus: string;
    ScanDateAndTime: string;
    ScanPattern: string;
    Laterality: string;
    Manufacturer: string;
    Device: string;
    FileName: string;
    ImageQuality: number;
    RasterAngleInDeg: number;
    FirstBscanStartXInMm: number;
    FirstBscanStartYInMm: number;
    LastBscanStartXInMm: number;
    LastBscanStartYInMm: number;
    BscanWidthDim: number;
    BscanHeightDim: number;
    NumSlices: number;
    BscanWidthSizeInMicrons: number;
    BscanHeightSizeInMicrons: number;
    NumSlicesSizeInMicrons: number;
    UniqueId: string;
    SiteId: string;
    SeriesId: number;
    Comment: string;
}

export interface UserDTO {
    userID: string;
    loginInfo: ILoginInfo;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    _activeOrgUuid: string;
    avatarURL: string;
    activated: boolean;
    adminFeatures: string;
    accessDate: string;
    accessExpires: string;
    accessOK: boolean;
    orgUuid: string;
    topOrgUuid: string;
    activeOrgUuid: string;
    projectOrgUuid: string;
    consentUuid: string;
}

export const mediaService: MediaService = {
    getEnFaceInfo: async (mediaId: string) => {
        const response = await nebulaClient.get<EnFaceInfoDTO>(`/media/image/${mediaId}/ef.json/0`);

        return response;
    },
    getAnalysis: async (studyId: string) => {
        const response = await nebulaClient.get<AnalysisDTO[]>(`/study/analysis/${studyId}`);

        return response;
    },
    getMediaInfo: async (mediaId: string) => {
        const response = await nebulaClient.get<MediaInfoDTO>(`media/info/${mediaId}`);

        return response;
    },
    getSiteUsers: async (mediaId: string) => {
        const response = await nebulaClient.get<UserDTO[]>(`/user/reachable?level=media&key=${mediaId}`);

        return response;
    },
    getTextFile: async (studyId: string) => {
        const response = await nebulaClient.get<Blob>(`/media/stream/txt/${studyId}`, { responseType: "blob" });

        return response;
    },
};
