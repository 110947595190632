import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { HighlightOff as HighlightOffIcon } from "@mui/icons-material";
import { useAppDispatch } from "../../app/hooks";
import { deselectPatient } from "../patients/patientsSlice";

interface IDrawerHeaderProps {}

const DrawerHeader: React.FC<IDrawerHeaderProps> = () => {
    const dispatch = useAppDispatch();

    const closeDrawer = () => {
        dispatch(deselectPatient());
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 12, py: 8 }}>
            <Typography>PATIENT CARD</Typography>
            <IconButton size="small" onClick={closeDrawer}>
                <HighlightOffIcon fontSize="large" />
            </IconButton>
        </Box>
    );
};

export default DrawerHeader;
