import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutUser, selectUserInfo } from "../auth/login/loginSlice";
import { useGetUserDataQuery } from "../auth/dataAccess";
import Menu from "../menu/Menu";
import DisplayText from "../../components/DisplayText/DisplayText";
import LogoImg from "../../assets/SquareLogo.png";
import ComingSoonFeature from "../../components/ComingSoonFeature/ComingSoonFeature";
import {
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
    Badge,
    Menu as AccountMenu,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    Button,
} from "@mui/material";
import { AccountCircle, Menu as MenuIcon, Notifications } from "@mui/icons-material";
import { Box, styled } from "@mui/system";
import theme from "../../app/theme";
import { ITrial } from "../upload/uploadSlice";
import { getActiveTrial, useGetTrialsQuery, useUpdateCurrentGroupMutation } from "../upload/dataAccess";
import { useSnackbar } from "notistack";
import { deselectPatient } from "../patients/patientsSlice";
import { nebulaClient2 } from "../../lib/nebulaClient";

const Image = styled("img")(({ theme }) => ({
    maxHeight: theme.spacing(40),
    display: "none",
    [theme.breakpoints.up("sm")]: {
        display: "block",
    },
}));

const NavBar = () => {
    const userInfo = useAppSelector(selectUserInfo);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedTrial, setSelectedTrial] = useState("");
    const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(null);
    // const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
    const isProfileMenuOpen = Boolean(profileAnchorEl);
    const dispatch = useAppDispatch();

    const [menuOpen, setOpen] = useState(false);

    const { data: userData } = useGetUserDataQuery();

    const { data: trials, isLoading: trialsLoading } = useGetTrialsQuery();

    const [updateCurrentGroup] = useUpdateCurrentGroupMutation({
        fixedCacheKey: "groupChange",
    });

    useEffect(() => {
        if (trials) {
            const activeTrial = getActiveTrial(trials);
            setSelectedTrial(activeTrial!.uuid);
        }
    }, [trials]);

    const handleLogOut = (event: React.MouseEvent<HTMLElement>) => {
        dispatch(logoutUser());
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileAnchorEl(event.currentTarget);
    };

    // const handleNotificationsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    //     setNotificationsAnchorEl(event.currentTarget);
    // };

    const handleProfileMenuClose = () => {
        setProfileAnchorEl(null);
    };

    // const handleNotificationsMenuClose = () => {
    //     setNotificationsAnchorEl(null);
    // };

    // const handleVisitDashboard = () => {
    //     history.push("/dashboard");
    //     handleNotificationsMenuClose();
    // };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }
        setOpen(open);
    };

    const menuId = "primary-search-account-menu";

    const renderProfileMenu = (
        <AccountMenu
            anchorEl={profileAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isProfileMenuOpen}
            onClose={handleProfileMenuClose}
        >
            <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
        </AccountMenu>
    );

    // const renderNotificationsMenu = (
    //     <ComingSoonFeature>
    //         <AccountMenu
    //             anchorEl={notificationsAnchorEl}
    //             anchorOrigin={{ vertical: "top", horizontal: "right" }}
    //             id="notificationsMenu"
    //             transformOrigin={{ vertical: "top", horizontal: "right" }}
    //             // open={isNotificationsMenuOpen}
    //             // onClose={handleNotificationsMenuClose}
    //         >
    //             {/*<MenuItem onClick={handleVisitDashboard}>*/}
    //             {/*    <ListItemIcon>*/}
    //             {/*        <InfoIcon fontSize="small" />*/}
    //             {/*    </ListItemIcon>*/}
    //             {/*    <Box sx={{ display: "flex", flexDirection: "column" }}>*/}
    //             {/*        <DisplayText type="bodyMedium" text="Visit new dashboard!" />*/}
    //             {/*        <DisplayText type="bodyXSmall" style={{ color: "#d9d9d9" }} text="08-08-2022" />*/}
    //             {/*    </Box>*/}
    //             {/*</MenuItem>*/}
    //             {/*<MenuItem onClick={handleNotificationsMenuClose}>*/}
    //             {/*    <ListItemIcon>*/}
    //             {/*        <AssignmentIcon fontSize="small" />*/}
    //             {/*    </ListItemIcon>*/}
    //             {/*    <Box sx={{ display: "flex", flexDirection: "column" }}>*/}
    //             {/*        <DisplayText type="bodyMedium" text="New study added" />*/}
    //             {/*        <DisplayText type="bodyXSmall" style={{ color: "#d9d9d9" }} text="12-12-2021" />*/}
    //             {/*    </Box>*/}
    //             {/*</MenuItem>*/}
    //             {/*<MenuItem onClick={handleNotificationsMenuClose}>*/}
    //             {/*    <ListItemIcon>*/}
    //             {/*        <AssignmentIcon fontSize="small" />*/}
    //             {/*    </ListItemIcon>*/}
    //             {/*    <Box sx={{ display: "flex", flexDirection: "column" }}>*/}
    //             {/*        <DisplayText type="bodyMedium" text="New study reviewed" />*/}
    //             {/*        <DisplayText type="bodyXSmall" style={{ color: "#d9d9d9" }} text="12-10-2021" />*/}
    //             {/*    </Box>*/}
    //             {/*</MenuItem>*/}
    //             {/*<MenuItem onClick={handleNotificationsMenuClose}>*/}
    //             {/*    <ListItemIcon>*/}
    //             {/*        <AssignmentIcon fontSize="small" />*/}
    //             {/*    </ListItemIcon>*/}
    //             {/*    <Box sx={{ display: "flex", flexDirection: "column" }}>*/}
    //             {/*        <DisplayText type="bodyMedium" text="New study added" />*/}
    //             {/*        <DisplayText type="bodyXSmall" style={{ color: "#d9d9d9" }} text="12-01-2021" />*/}
    //             {/*    </Box>*/}
    //             {/*</MenuItem>*/}
    //             {/*<MenuItem onClick={handleNotificationsMenuClose}>*/}
    //             {/*    <ListItemIcon>*/}
    //             {/*        <AssignmentIcon fontSize="small" />*/}
    //             {/*    </ListItemIcon>*/}
    //             {/*    <Box sx={{ display: "flex", flexDirection: "column" }}>*/}
    //             {/*        <DisplayText type="bodyMedium" text="New study reviewed" />*/}
    //             {/*        <DisplayText type="bodyXSmall" style={{ color: "#d9d9d9" }} text="11-22-2021" />*/}
    //             {/*    </Box>*/}
    //             {/*</MenuItem>*/}
    //         </AccountMenu>
    //     </ComingSoonFeature>
    // );

    // @ts-ignore
    const renderTrialItem = (trial: ITrial) => [
        ...(trial.current.kind === "trial"
            ? [
                  <MenuItem
                      sx={{
                          ...(trial.current.depth > 0 && {
                              marginLeft: trial.current.depth * 32,
                              borderLeft: "1px solid",
                          }),
                      }}
                      value={trial.current.uuid}
                  >
                      {trial.current.label}
                  </MenuItem>,
              ]
            : []),
        trial.children.map(renderTrialItem),
    ];

    const trialItems = useMemo(() => {
        if (!trials) return;

        return trials.map(renderTrialItem);
    }, [trials]);

    const handleChangeGroup = async (e: any, a: any) => {
        const currentSelectedTrial = selectedTrial;
        const selectedGroup = e.target.value;
        try {
            setSelectedTrial(selectedGroup);
            dispatch(deselectPatient());
            await updateCurrentGroup({ uuid: selectedGroup }).unwrap();
        } catch (e) {
            setSelectedTrial(currentSelectedTrial);
            enqueueSnackbar("Something wrong happened.", {
                variant: "error",
            });
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar sx={{ bgcolor: theme.palette.background.default, p: "0px 4px" }} position="static">
                <Toolbar>
                    {userInfo.isLoggedIn ? (
                        <>
                            <IconButton onClick={toggleDrawer(true)} aria-label="open menu">
                                <MenuIcon />
                            </IconButton>
                            <Drawer open={menuOpen} onClose={toggleDrawer(false)}>
                                <Menu onClose={toggleDrawer(false)} />
                            </Drawer>
                        </>
                    ) : null}
                    <Link to="/">
                        <Image src={LogoImg} alt="VoxeleronLogo" />
                    </Link>
                    {userInfo.isLoggedIn ? (
                        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, ml: 10 }}>
                            <Box>
                                <DisplayText
                                    type="h5"
                                    variant="regular"
                                    text="iNebula"
                                    style={{ marginBottom: "0px" }}
                                />
                                <DisplayText
                                    type="bodyXSmall"
                                    variant="regular"
                                    text="by Voxeleron"
                                    style={{ marginBottom: "0px" }}
                                />
                            </Box>
                            <Box sx={{ alignSelf: "flex-end" }}>
                                <FormControl
                                    size="small"
                                    key={trials && trials.length > 0 ? trials![0].current.uuid : ""}
                                    sx={{ m: 1, minWidth: 250 }}
                                >
                                    <InputLabel>{!trials ? "Loading..." : "Trials"}</InputLabel>
                                    <Select
                                        autoWidth={false}
                                        label="Loading..."
                                        value={selectedTrial}
                                        disabled={trialsLoading}
                                        onChange={handleChangeGroup}
                                    >
                                        {trialItems}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: "none",
                                    [theme.breakpoints.up("md")]: {
                                        display: "flex",
                                        alignItems: "center",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "none",
                                        [theme.breakpoints.up("sm")]: {
                                            display: "flex",
                                            gap: "8px",
                                        },
                                    }}
                                >
                                    <ComingSoonFeature>
                                        <IconButton aria-label="Notifications">
                                            <Badge badgeContent={5} color="secondary">
                                                <Notifications />
                                            </Badge>
                                        </IconButton>
                                    </ComingSoonFeature>
                                    <Button
                                        aria-label="My Account"
                                        aria-controls={menuId}
                                        size="large"
                                        aria-haspopup="true"
                                        startIcon={<AccountCircle />}
                                        onClick={handleProfileMenuOpen}
                                    >
                                        <DisplayText
                                            style={{ color: "white" }}
                                            text={userData!.email}
                                            type="bodyMedium"
                                            variant="regular"
                                        />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    ) : null}
                </Toolbar>
            </AppBar>
            {renderProfileMenu}
            {/*{renderNotificationsMenu}*/}
        </Box>
    );
};
export default NavBar;
