import { useCallback } from "react";
import { IStudy } from "./studiesSlice";

const useSelectStudiesByType = (
    studyType: string,
    selectedStudies: Set<string>,
    setSelectedStudies: (studiesIds: Set<string>) => void
) => {
    const areAllStudyTypesSelected = useCallback((visits: IStudy[], selectedStudies: Set<string>) => {
        if (!visits) return false;
        const studiesIds = visits!
            .map((study) => study.mediaOctFile.map((octFile) => octFile))
            .flat()
            .filter((octFile) => octFile.mimeType.label === studyType)
            .map((octFile) => octFile.uuid);

        if (studiesIds.length === 0) return false;

        return studiesIds.every((studyId) => selectedStudies.has(studyId));
    }, []);

    const selectAllStudiesType = (visits: IStudy[]) => {
        if (visits) {
            const studiesIds = visits
                .map((study) => study.mediaOctFile.map((octFile) => octFile))
                .flat()
                .filter((octFile) => octFile.mimeType.label === studyType)
                .map((octFile) => octFile.uuid);

            const newSelectedStudies = new Set(Array.from(selectedStudies));

            studiesIds.forEach((studyId) => newSelectedStudies.add(studyId));

            setSelectedStudies(newSelectedStudies);
        }
    };

    const deselectAllStudiesType = (visits: IStudy[]) => {
        const selectedStudiesIds = Array.from(selectedStudies);

        const allStudies = visits!.map((study) => study.mediaOctFile.map((octFile) => octFile)).flat();

        const selectedStudiesIdsWithoutStudyType = selectedStudiesIds
            .map((studyId) => allStudies.find((study) => study.uuid === studyId))
            .filter((study) => study!.mimeType.label !== studyType)
            .map((study) => study!.uuid);

        const newSelectedStudies = new Set(selectedStudiesIdsWithoutStudyType);

        setSelectedStudies(newSelectedStudies);
    };

    return { areAllStudyTypesSelected, selectAllStudiesType, deselectAllStudiesType };
};

export default useSelectStudiesByType;
